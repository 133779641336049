import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DebitCardApplicationDataStore } from '../../services/debit-card-application-data.store';
import { MasterDataService } from '../../services/master-data.service';
import { ErrorHandlingService } from '../../../../../../../goldstar-share/src/app/services/error-handling.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DebitCardApplicationService } from '../../services/debit-card-application.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DebitCardApplicationItem, DebitCardApplication, EmploymentStatus, State, CitizenshipType } from '../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { DxValidationGroupComponent } from 'devextreme-angular';
import { S3FileService } from '../../../../../../../goldstar-share/src/app/services/s3file-service';
import { MatDialog } from '@angular/material/dialog';
import { FileAcknowledgeComponent } from '../../../../../../../goldstar-share/src/app/components/shared/file-acknowledge/file-acknowledge.component';
import { DebitCardApplicationInfo, DebitCardApplicationStage, DebitCardApplicationStatus, SpinnerState } from '../../models/model';
import { FormOperationMode } from '../../../../../../../goldstar-internal/src/app/models/models';
import { BaseApplicationComponent } from '../base-application/base-application.component';
import { TokenProviderService } from '../../services/token-provider.service';
import { EditorStyle, LabelMode } from 'devextreme/common';

@Component({
	selector: 'app-debit-card-application3',
	templateUrl: './debit-card-application3.component.html',
	styleUrls: ['./debit-card-application3.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DebitCardApplication3Component extends BaseApplicationComponent implements OnInit {
	showSpinner!: boolean;

	application3Form: UntypedFormGroup = this.formBuilder.group({
		citizenshipTypeGUID: [null, Validators.required],
		socialSecurityNumber: [null, Validators.required],
		dOB: [null, Validators.required],
		birthCity: [null, Validators.required],
		birthState: [null, Validators.required],
		birthCountry: [null, Validators.required],
		employmentStatusGUID: [null, Validators.required],
		occupation: [null, Validators.required],
		employer: [null, Validators.required],
	});

	docReviewedInfoMessage: string = 'Please open and read disclosure';
	docReviewedErrorVisibleClass: string = 'error-message-visible';
	docReviewedErrorHiddenClass: string = 'error-message-hidden';
	docReviewedInfoVisibleClass: string = 'info-message-visible';
	docReviewedInfoHiddenClass: string = 'info-message-hidden';

	stylingMode: EditorStyle = 'outlined';
	labelMode: LabelMode = 'hidden';

	allEmploymentStatus: EmploymentStatus[] = [];
	allEmploymentStatusValues: string[] = [];
	selectedEmploymentStatus!: string;

	selectedEmploymentStatusErrorMessage!: boolean;

	allStates: State[] = [];
	allCitizenshipTypes: CitizenshipType[] = [];
	allCitizenshipTypeValues: string[] = [];
	selectedCitizenshipType!: string;
	selectedCitizenshipTypeErrorMessage!: boolean;

	selectedDebitCardApplication!: DebitCardApplicationItem;

	freeDebitCardY: boolean | null | undefined = false;
	freeDebitCardN: boolean | null | undefined = false;

	freeDebitCardErrorMarker: boolean = false;
	freeDebitCardErrorMessage: boolean = false;

	enableFreeDebitCardY: boolean = true;
	enableFreeDebitCardN: boolean = true;

	foreignDelegateY: boolean | null | undefined = false;
	foreignDelegateN: boolean | null | undefined = false;

	enableForeignDelegateN: boolean = true;
	enableForeignDelegateY: boolean = true;

	foreignDelegateErrorMarker: boolean = false;
	foreignDelegateErrorMessage: boolean = false;

	//#region  Overdraft Protection
	overdraftProtectionOptIn: boolean | null | undefined = false;
	allowOverdraftProjectionOptInUpdate: boolean = false;
	overdraftProtectionOptOut: boolean | null | undefined = false;
	allowOverdraftProjectionOptOutUpdate: boolean = false;
	hasOverdraftProtectionDocViewed: boolean = false;
	overDraftProtectionDisclosureStyleClass: string = this.docReviewedInfoHiddenClass;
	overDraftProtectionReviewNeededMessage: string = 'Choose either Opt in or Opt out';
	overDraftProtectionMessage: string = '';
	//#endregion

	//#region Regulation E
	regulationYN: boolean | null | undefined = false;
	allowRegulationYNUpdate: boolean = false;
	hasRegulationDocViewed: boolean = false;
	regulationEStyleClass: string = this.docReviewedInfoHiddenClass;
	regulationENeededMessage: string = 'Read and agree with above disclosure';
	regulationEMessage: string = '';
	//#endregion

	//#region  Disclosure Agreement & E Signature
	disclosureAgreementYN: boolean | null | undefined = false;
	allowDisclosureAgreementYNUpdate: boolean = false;
	hasDisclosureAgreementDocViewed: boolean = false;
	eSignatureStyleClass: string = this.docReviewedInfoHiddenClass;
	eSignatureNeededMessage: string = 'Read and agree with above disclosure';
	eSignatureMessage: string = '';
	//#endregion

	//#region  Combined Disclosure
	combinedDisclosureYN: boolean | null | undefined = false;
	allowUpdateCombinedDisclosureYN: boolean = false;
	hasCombinedDisclosureDocViewed: boolean = false;
	combinedDisclosureStyleClass: string = this.docReviewedInfoHiddenClass;
	combinedDisclosureNeededMessage: string = 'Read and agree with above disclosure';
	combinedDisclosureMessage: string = '';
	//#endregion

	regulationEDisclosureErrorMessage: boolean = false;

	eSignatureDisclosureErrorMessage: boolean = false;

	privacyPolicyDisclosureErrorMessage: boolean = false;

	userIdentificationLabel!: string;

	public isEdit: boolean = false;

	// This is for full footer (all 3 columns)
	fullFooterLayout: boolean = true;

	// This is one column (only continue button)
	trimmedFooter: boolean = false;

	@HostListener('window:load', ['$event'])
	onLoad() {
		if (window.innerWidth <= 500) {
			this.fullFooterLayout = false;
			this.trimmedFooter = true;
		} else {
			this.fullFooterLayout = true;
			this.trimmedFooter = false;
		}
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize(event: any) {
		if (window.innerWidth <= 500) {
			this.fullFooterLayout = false;
			this.trimmedFooter = true;
		} else {
			this.fullFooterLayout = true;
			this.trimmedFooter = false;
		}
	}

	@ViewChild('application3FormValidationGroup', { static: false }) application3FormValidationGroup!: DxValidationGroupComponent;

	constructor(
		formBuilder: UntypedFormBuilder,
		activatedRoute: ActivatedRoute,
		errorHandlingService: ErrorHandlingService,
		spinnerService: NgxSpinnerService,
		router: Router,
		debitCardApplicationService: DebitCardApplicationService,
		store: DebitCardApplicationDataStore,
		masterDataService: MasterDataService,
		private tokenProviderService: TokenProviderService,
		private fileService: S3FileService,
		private dialog: MatDialog
	) {
		super(formBuilder, errorHandlingService, spinnerService, store, router, activatedRoute, debitCardApplicationService, masterDataService);
	}

	async ngOnInit(): Promise<void> {
		this.toggleSpinner(SpinnerState.Show);

		this.allCitizenshipTypes = await this.masterDataService.fetchAllCitizenshipTypes();
		this.allCitizenshipTypeValues = this.allCitizenshipTypes.map((x) => x.label ?? '');
		this.selectedCitizenshipType = this.allCitizenshipTypeValues[0];

		this.allEmploymentStatus = await this.masterDataService.fetchAllEmploymentStatus();
		this.allEmploymentStatusValues = this.allEmploymentStatus.map((x) => x.label ?? '');
		this.selectedEmploymentStatus = this.allEmploymentStatusValues[0];

		this.allStates = await this.masterDataService.fetchAllState();

		if (this.selectedDebitCardApplication) {
			this.bindFormValue();
		}
		this.toggleSpinner(SpinnerState.Hide);

		// let masterDataRequest = [this.masterDataService.fetchAllCitizenshipTypes(), this.masterDataService.fetchAllEmploymentStatus(), this.masterDataService.fetchAllState()];
		// await Promise.all(masterDataRequest)
		// 	.then(async (response) => {
		// 		this.toggleSpinner(SpinnerState.Hide);
		// 		this.allCitizenshipTypes = response[0] as CitizenshipType[];
		// 		this.allEmploymentStatus = response[1] as EmploymentStatus[];
		// 		this.allEmploymentStatusValues = this.allEmploymentStatus.map((x) => x.label ?? '');
		// 		this.selectedEmploymentStatus = this.allEmploymentStatusValues[0];

		// 		this.allStates = response[2] as State[];
		// 		this.allCitizenshipTypeValues = this.allCitizenshipTypes.map((x) => x.label ?? '');
		// 		this.selectedCitizenshipType = this.allCitizenshipTypeValues[0];
		// 		if (this.selectedDebitCardApplication) {
		// 			this.bindFormValue();
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		this.toggleSpinner(SpinnerState.Hide);
		// 		this.errorHandlingService.showErrorMessage('Application failed to load, please try back after some time!');
		// 	});
	}

	bindFormValue() {
		if (this.selectedDebitCardApplication) {
			const matchingCitizenshipType = this.allCitizenshipTypes.find((x) => x.citizenshipTypeGUID === this.selectedDebitCardApplication.citizenTypeGUID);
			this.selectedCitizenshipType = matchingCitizenshipType ? matchingCitizenshipType.label ?? '' : this.allCitizenshipTypeValues[0];

			const matchingEmploymentStatus = this.allEmploymentStatus.find((x) => x.employmentStatusGUID === this.selectedDebitCardApplication.employeeStatusGUID);
			this.selectedEmploymentStatus = matchingEmploymentStatus ? matchingEmploymentStatus.label ?? '' : this.allEmploymentStatusValues[0];
		}
		this.application3Form.controls.socialSecurityNumber.setValue(this.selectedDebitCardApplication.ssn);
		this.application3Form.controls.dOB.setValue(this.selectedDebitCardApplication.dob);
		this.application3Form.controls.birthCity.setValue(this.selectedDebitCardApplication.birthCity);
		this.application3Form.controls.birthState.setValue(this.selectedDebitCardApplication.birthState);
		this.application3Form.controls.birthCountry.setValue(this.selectedDebitCardApplication.birthCountry);
		this.application3Form.controls.occupation.setValue(this.selectedDebitCardApplication.occupation);
		this.application3Form.controls.employer.setValue(this.selectedDebitCardApplication.employer);
		this.allowDisclosureAgreementYNUpdate = this.selectedDebitCardApplication.disclosureAgreementDocViewedYN ?? false;
		this.allowUpdateCombinedDisclosureYN = this.selectedDebitCardApplication.combinedDisclosureDocViewedYN ?? false;
		this.allowRegulationYNUpdate = this.selectedDebitCardApplication.regulationDocViewedYN ?? false;
		this.allowOverdraftProjectionOptInUpdate = this.selectedDebitCardApplication.overdraftProtectionDocViewedYN ?? false;
		this.allowOverdraftProjectionOptOutUpdate = this.selectedDebitCardApplication.overdraftProtectionDocViewedYN ?? false;

		//Bind the boolean values
		if (this.selectedDebitCardApplication.foreignDelegateYN) {
			this.foreignDelegateY = this.selectedDebitCardApplication.foreignDelegateYN;
			this.foreignDelegateN = !this.selectedDebitCardApplication.foreignDelegateYN;
		}
		if (this.selectedDebitCardApplication.foreignDelegateYN) {
			this.freeDebitCardY = this.selectedDebitCardApplication.freeDebitCardYN;
			this.freeDebitCardN = !this.selectedDebitCardApplication.freeDebitCardYN;
		}
		if (this.selectedDebitCardApplication.overdraftProjectionYN) {
			this.overdraftProtectionOptIn = this.selectedDebitCardApplication.overdraftProjectionYN;
			this.overdraftProtectionOptOut = !this.selectedDebitCardApplication.overdraftProjectionYN;
		}
		if (this.selectedDebitCardApplication.regulationYN) {
			this.regulationYN = this.selectedDebitCardApplication.regulationYN;
		}
		if (this.selectedDebitCardApplication.combinedDisclosureYN) {
			this.combinedDisclosureYN = this.selectedDebitCardApplication.combinedDisclosureYN;
		}
		if (this.selectedDebitCardApplication.disclosureAgreementYN) {
			this.disclosureAgreementYN = this.selectedDebitCardApplication.disclosureAgreementYN;
		}
	}

	onForeignDelegateValueTrue(event: any) {
		if (this.freeDebitCardY || this.freeDebitCardN) {
			this.freeDebitCardErrorMessage = false;
		}
		this.enableForeignDelegateN = true;
		this.foreignDelegateN = !this.foreignDelegateY;
	}

	onForeignDelegateValueFalse(event: any) {
		if (this.freeDebitCardY || this.freeDebitCardN) {
			this.freeDebitCardErrorMessage = false;
		}
		this.enableForeignDelegateY = true;
		this.foreignDelegateY = !this.foreignDelegateN;
	}

	async acknowledgeOverdraftProtectionDoc() {
		try {
			this.errorHandlingService.showInfoMessage('Retrieving file');
			this.allowOverdraftProjectionOptInUpdate = true;
			this.allowOverdraftProjectionOptOutUpdate = true;
			let fileGUID = 'Overdraft Protection.zip';
			let file: File = await this.fileService.downloadStaticS3File(fileGUID);
			let filePath = URL.createObjectURL(file);
			let dialogRef = this.dialog.open(FileAcknowledgeComponent, {
				width: '90%',
				height: '90%',
				data: {
					fileLabel: 'Overdraft Protection',
					file: file,
					acknoWledgeDocLabel: 'Yes, Opt in',
					cancelDoc: 'Opt out',
					onAcknowledgeDoc: (data: any) => {
						dialogRef.close();
						this.allowOverdraftProjectionOptInUpdate = true;
						this.allowOverdraftProjectionOptOutUpdate = true;
					},
					onCancel: (data: any) => {
						dialogRef.close();
						this.allowOverdraftProjectionOptInUpdate = true;
						this.allowOverdraftProjectionOptOutUpdate = true;
					},
				},
			});
			dialogRef.afterClosed().subscribe(() => {
				URL.revokeObjectURL(filePath);
			});
		} catch (e) {
			console.log('error: ', e);
			this.errorHandlingService.showErrorMessage('Error retrieving file');
		}
	}

	async acknowledgeRegulationEDoc() {
		try {
			this.errorHandlingService.showInfoMessage('Retrieving file');
			this.allowRegulationYNUpdate = true;
			let fileGUID = 'Reg E.zip';
			let file: File = await this.fileService.downloadStaticS3File(fileGUID);
			let filePath = URL.createObjectURL(file);
			let dialogRef = this.dialog.open(FileAcknowledgeComponent, {
				width: '90%',
				height: '90%',
				data: {
					fileLabel: 'Reg E',
					file: file,
					showTermsAndConditionLabel: true,
					termsAndConditionLabelContent: 'I have read and agree to all of the above communications from Texas Regional Bank',
					acknoWledgeDocLabel: 'Submit',
					cancelDoc: 'Cancel',
					onAcknowledgeDoc: (data: any) => {
						dialogRef.close();
						this.allowRegulationYNUpdate = true;
					},
					onCancel: (data: any) => {
						dialogRef.close();
						this.allowRegulationYNUpdate = true;
					},
				},
			});
			dialogRef.afterClosed().subscribe(() => {
				URL.revokeObjectURL(filePath);
			});
		} catch (e) {
			console.log('error: ', e);
			this.errorHandlingService.showErrorMessage('Error retrieving file');
		}
	}

	async acknowledgeDisclosureAgreementDoc() {
		try {
			this.errorHandlingService.showInfoMessage('Retrieving file');
			this.allowDisclosureAgreementYNUpdate = true;
			let fileGUID = 'E-signature Consent and Agreement.zip';
			let file: File = await this.fileService.downloadStaticS3File(fileGUID);
			let filePath = URL.createObjectURL(file);
			let dialogRef = this.dialog.open(FileAcknowledgeComponent, {
				width: '90%',
				height: '90%',
				data: {
					fileLabel: 'E-signature Consent and Agreement',
					file: file,
					acknoWledgeDocLabel: 'I have read the disclosure',
					cancelDoc: 'Cancel',
					onAcknowledgeDoc: (data: any) => {
						dialogRef.close();
						this.allowDisclosureAgreementYNUpdate = true;
					},
					onCancel: (data: any) => {
						dialogRef.close();
						this.allowDisclosureAgreementYNUpdate = true;
					},
				},
			});
			dialogRef.afterClosed().subscribe(() => {
				URL.revokeObjectURL(filePath);
			});
		} catch (e) {
			console.log('error: ', e);
			this.errorHandlingService.showErrorMessage('Error retrieving file');
		}
	}

	async acknowledgePrivacyPolicyDoc() {
		try {
			this.errorHandlingService.showInfoMessage('Retrieving file');
			this.allowUpdateCombinedDisclosureYN = true;
			let fileGUID = 'Privacy Policy and Combined Disclosures.zip';
			let file: File = await this.fileService.downloadStaticS3File(fileGUID);
			let filePath = URL.createObjectURL(file);
			let dialogRef = this.dialog.open(FileAcknowledgeComponent, {
				width: '90%',
				height: '90%',
				data: {
					fileLabel: 'Privacy Policy and Combined Disclosures',
					file: file,
					showTermsAndConditionLabel: true,
					termsAndConditionLabelContent: 'I have read and agree to all of the above communications from Texas Regional Bank',
					acknoWledgeDocLabel: 'Submit',
					cancelDoc: 'Cancel',
					onAcknowledgeDoc: (data: any) => {
						dialogRef.close();
						this.allowUpdateCombinedDisclosureYN = true;
					},
					onCancel: (data: any) => {
						dialogRef.close();
						this.allowUpdateCombinedDisclosureYN = true;
					},
				},
			});
			dialogRef.afterClosed().subscribe(() => {
				URL.revokeObjectURL(filePath);
			});
		} catch (e) {
			console.log('error: ', e);
			this.errorHandlingService.showErrorMessage('Error retrieving file');
		}
	}

	toggleSpinner(state: SpinnerState) {
		switch (state) {
			case SpinnerState.Show:
				this.spinnerService.show();
				this.showSpinner = true;
				break;
			case SpinnerState.Hide:
			default:
				this.spinnerService.hide();
				this.showSpinner = false;
				break;
		}
	}

	async navigateToApplicationSummary() {
		this.operationMode = FormOperationMode.Save;
		const validationResult = this.validateAllMandatoryFields();
		if (validationResult) {
			this.toggleSpinner(SpinnerState.Show);
			const matchingEmploymentStatus = this.allEmploymentStatus.find((x) => x.label === this.selectedEmploymentStatus);
			const matchingCitizenshipType = this.allCitizenshipTypes.find((x) => x.label === this.selectedCitizenshipType);
			const updateDebitCardApplication: DebitCardApplicationInfo = {
				employeeStatusGUID: matchingEmploymentStatus ? matchingEmploymentStatus.employmentStatusGUID : '',
				citizenTypeGUID: matchingCitizenshipType ? matchingCitizenshipType.citizenshipTypeGUID : '',
				ssn: this.application3Form.controls.socialSecurityNumber.value,
				dob: this.application3Form.controls.dOB.value,
				birthCity: this.application3Form.controls.birthCity.value,
				birthState: this.application3Form.controls.birthState.value,
				birthCountry: this.application3Form.controls.birthCountry.value,
				occupation: this.application3Form.controls.occupation.value,
				employer: this.application3Form.controls.employer.value,
				foreignDelegateYN: this.foreignDelegateY ?? false,
				freeDebitCardYN: this.freeDebitCardY ?? false,
				overdraftProjectionYN: this.overdraftProtectionOptIn ?? false,
				overdraftProtectionDocViewedYN: true,
				regulationYN: this.regulationYN ?? false,
				regulationDocViewedYN: true,
				disclosureAgreementYN: this.disclosureAgreementYN ?? false,
				disclosureAgreementDocViewedYN: true,
				combinedDisclosureYN: this.combinedDisclosureYN ?? false,
				combinedDisclosureDocViewedYN: true,
			};
			await this.store.updateStatus(DebitCardApplicationStatus.IN_PROCESS_IDENTITY_VERIFICATION);
			await this.store.patchValues(updateDebitCardApplication);
			await this.store
				.push()
				.then(async (response) => {
					this.toggleSpinner(SpinnerState.Hide);
					this.errorHandlingService.showSuccessMessage('Successfully saved application');
					this.routeSelector('debitcardppplication/applicationSummary');
				})
				.catch((error) => {
					this.toggleSpinner(SpinnerState.Hide);
					this.errorHandlingService.showErrorMessage('Failed to save application');
				});
		}
	}

	validateAllMandatoryFields(): boolean | undefined {
		const validationResult = this.application3FormValidationGroup.instance.validate();
		const validateOverdraftDisclosure = this.validateOverdraftProtectionDisclosure();
		const validateRegulationEDisclosure = this.validateRegulationEDisclosure();
		const validateESignatureDisclosure = this.validateESignatureDisclosure();
		const validatePrivacyDisclosure = this.validatePrivacyPolicyDisclosure();
		const validateFreeDebitCard = this.validateFreeDebitCard();
		const validateForeignDelegate = this.validateForeignDelegate();
		const validateCitizenType = this.validateCitizenshipTypeValue();
		const validateEmploymentStatus = this.validateEmploymentStatusValue();
		return (
			validationResult.isValid &&
			validateOverdraftDisclosure &&
			validateRegulationEDisclosure &&
			validateESignatureDisclosure &&
			validatePrivacyDisclosure &&
			validateFreeDebitCard &&
			validateForeignDelegate &&
			validateCitizenType &&
			validateEmploymentStatus
		);
	}

	validateFreeDebitCard(): boolean | undefined {
		this.freeDebitCardErrorMarker = !this.freeDebitCardN && !this.freeDebitCardY;
		return !this.freeDebitCardErrorMarker;
	}

	showFreeDebitCardValidationMessage() {
		this.freeDebitCardErrorMessage = true;
	}

	onFreeDebitCardValueTrue(event: any) {
		if (this.foreignDelegateY || this.foreignDelegateN) {
			this.foreignDelegateErrorMessage = false;
		}
		this.enableFreeDebitCardN = true;
		this.freeDebitCardN = !this.freeDebitCardY;
		this.freeDebitCardErrorMarker = false;
		this.freeDebitCardErrorMessage = false;
	}

	onFreeDebitCardValueFalse(event: any) {
		if (this.foreignDelegateY || this.foreignDelegateN) {
			this.foreignDelegateErrorMessage = false;
		}
		this.enableFreeDebitCardY = true;
		this.freeDebitCardY = !this.freeDebitCardN;
		this.freeDebitCardErrorMarker = false;
		this.freeDebitCardErrorMessage = false;
	}

	validateForeignDelegate(): boolean | undefined {
		this.foreignDelegateErrorMarker = !this.foreignDelegateN && !this.foreignDelegateY;
		return !this.foreignDelegateErrorMarker;
	}

	showForeignDelegateErrorMessage() {
		this.foreignDelegateErrorMessage = true;
	}

	validateOverdraftProtectionDisclosure(): boolean {
		const hasErrors = !this.overdraftProtectionOptIn && !this.overdraftProtectionOptOut;
		if (hasErrors) {
			this.overDraftProtectionDisclosureStyleClass = this.docReviewedErrorVisibleClass;
			this.overDraftProtectionMessage = this.overDraftProtectionReviewNeededMessage;
		} else {
			this.overDraftProtectionDisclosureStyleClass = this.docReviewedErrorHiddenClass;
			this.overDraftProtectionMessage = '';
		}
		return !hasErrors;
	}

	validateRegulationEDisclosure(): boolean {
		const hasErrors = !this.regulationYN;
		if (hasErrors) {
			this.regulationEStyleClass = this.docReviewedErrorVisibleClass;
			this.regulationEMessage = this.regulationENeededMessage;
		} else {
			this.regulationEStyleClass = this.docReviewedErrorHiddenClass;
			this.regulationEMessage = '';
		}
		return !hasErrors;
	}

	validateESignatureDisclosure(): boolean {
		const hasErrors = !this.disclosureAgreementYN;
		if (hasErrors) {
			this.eSignatureStyleClass = this.docReviewedErrorVisibleClass;
			this.eSignatureMessage = this.eSignatureNeededMessage;
		} else {
			this.eSignatureStyleClass = this.docReviewedErrorHiddenClass;
			this.eSignatureMessage = '';
		}
		return !hasErrors;
	}

	validatePrivacyPolicyDisclosure(): boolean {
		const hasErrors = !this.combinedDisclosureYN;
		if (hasErrors) {
			this.combinedDisclosureStyleClass = this.docReviewedErrorVisibleClass;
			this.combinedDisclosureMessage = this.eSignatureNeededMessage;
		} else {
			this.combinedDisclosureStyleClass = this.docReviewedErrorHiddenClass;
			this.combinedDisclosureMessage = '';
		}
		return !hasErrors;
	}

	validateCitizenshipTypeValue(): boolean {
		if (this.selectedCitizenshipType === 'I am neither') {
			this.selectedCitizenshipTypeErrorMessage = true;
			return false;
		} else {
			this.selectedCitizenshipTypeErrorMessage = false;
			return true;
		}
	}

	validateEmploymentStatusValue(): boolean {
		if (!this.selectedEmploymentStatus) {
			this.selectedEmploymentStatusErrorMessage = true;
			return false;
		} else {
			this.selectedEmploymentStatusErrorMessage = false;
			return true;
		}
	}

	onOverdraftOptInChange(e: any) {
		if (e.value) {
			this.overDraftProtectionDisclosureStyleClass = this.docReviewedErrorHiddenClass;
			this.overDraftProtectionMessage = '';
			if (this.overdraftProtectionOptOut) {
				this.overdraftProtectionOptOut = false;
			}
		}
	}

	onOverdraftOptOutChange(e: any) {
		if (e.value) {
			this.overDraftProtectionDisclosureStyleClass = this.docReviewedErrorHiddenClass;
			this.overDraftProtectionMessage = '';
			if (this.overdraftProtectionOptIn) {
				this.overdraftProtectionOptIn = false;
			}
		}
	}

	onElectronicDisclosureChange(e: any) {
		if (e.value) {
			this.eSignatureStyleClass = this.docReviewedErrorHiddenClass;
		}
	}

	onPrivacyPolicyDisclosureChange(e: any) {
		if (e.value) {
			this.combinedDisclosureStyleClass = this.docReviewedErrorHiddenClass;
		}
	}

	onRegulationEDisclosureChange(e: any) {
		if (e.value) {
			this.regulationEStyleClass = this.docReviewedErrorHiddenClass;
		}
	}

	/**
	 * Displays doc review info message
	 * @param e : eventParam
	 */
	onOverdraftProtectionOptInMouseOver(e: any) {
		// if error is already shown to the user or the document is already viewed, then we don't need to show the info message
		if (this.overDraftProtectionDisclosureStyleClass != this.docReviewedErrorVisibleClass && (!this.allowOverdraftProjectionOptInUpdate || !this.allowOverdraftProjectionOptOutUpdate)) {
			this.overDraftProtectionDisclosureStyleClass = this.docReviewedInfoVisibleClass;
			this.overDraftProtectionMessage = this.docReviewedInfoMessage;
		}
	}

	/**
	 * Hides doc review info message
	 * @param e : eventParam
	 */
	onOverdraftProtectionOptInMouseOut(e: any) {
		// Hide info message only if it is already shown to the user
		if (this.overDraftProtectionDisclosureStyleClass === this.docReviewedInfoVisibleClass) {
			this.overDraftProtectionDisclosureStyleClass = this.docReviewedInfoHiddenClass;
			this.overDraftProtectionMessage = '';
		}
	}

	/**
	 * Displays doc review info message
	 * @param e : eventParam
	 */
	onRegulationEMouseOver(e: any) {
		// if error is already shown to the user or the document is already viewed, then we don't need to show the info message
		if (this.regulationEStyleClass != this.docReviewedErrorVisibleClass && !this.allowRegulationYNUpdate) {
			this.regulationEStyleClass = this.docReviewedInfoVisibleClass;
			this.regulationEMessage = this.docReviewedInfoMessage;
		}
	}

	/**
	 * Hides doc review info message
	 * @param e : eventParam
	 */
	onRegulationEMoverOut(e: any) {
		// Hide info message only if it is already shown to the user
		if (this.regulationEStyleClass === this.docReviewedInfoVisibleClass) {
			this.regulationEStyleClass = this.docReviewedInfoHiddenClass;
			this.regulationEMessage = '';
		}
	}

	/**
	 * Displays doc review info message
	 * @param e : eventParam
	 */
	onESignatureDisclosureMouseOver(e: any) {
		// if error is already shown to the user or the document is already viewed, then we don't need to show the info message
		if (this.eSignatureStyleClass != this.docReviewedErrorVisibleClass && !this.allowDisclosureAgreementYNUpdate) {
			this.eSignatureStyleClass = this.docReviewedInfoVisibleClass;
			this.eSignatureMessage = this.docReviewedInfoMessage;
		}
	}

	/**
	 * Hides doc review info message
	 * @param e : eventParam
	 */
	onEsignatureDisclosureMouseOut(e: any) {
		// Hide info message only if it is already shown to the user
		if (this.eSignatureStyleClass === this.docReviewedInfoVisibleClass) {
			this.eSignatureStyleClass = this.docReviewedInfoHiddenClass;
			this.eSignatureMessage = '';
		}
	}

	/**
	 * Displays doc review info message
	 * @param e : eventParam
	 */
	onCombinedDisclosureMouseOver(e: any) {
		// if error is already shown to the user or the document is already viewed, then we don't need to show the info message
		if (this.combinedDisclosureStyleClass != this.docReviewedErrorVisibleClass && !this.allowUpdateCombinedDisclosureYN) {
			this.combinedDisclosureStyleClass = this.docReviewedInfoVisibleClass;
			this.combinedDisclosureMessage = this.docReviewedInfoMessage;
		}
	}

	/**
	 * Hides doc review info message
	 * @param e : eventParam
	 */
	onCombinedDisclosureMouseOut(e: any) {
		// Hide info message only if it is already shown to the user
		if (this.combinedDisclosureStyleClass === this.docReviewedInfoVisibleClass) {
			this.combinedDisclosureStyleClass = this.docReviewedInfoHiddenClass;
			this.combinedDisclosureMessage = '';
		}
	}
}
