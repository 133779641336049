<ngx-spinner *ngIf="showSpinner" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
	<p style="color: white">Loading...</p>
</ngx-spinner>
<div [fluidHeight] class="row col-lg-12 col-md-12 application-container">
	<div class="header">
		<div class="row col-lg-12 col-md-12">
			<div class="header-image-container">
				<img class="header-image" src="../../../assets/images/Trb_Bank_Logo_with_caption.png" />
			</div>
		</div>
		<div class="padding-top-large padding-left-small">
			<section>
				<h1 class="form-header-title">Let's get to know you</h1>
			</section>
			<section class="padding-top-xl">
				<h2 class="padding-left-small form-header-subtitle padding-bottom">Application - Section 3</h2>
				<span class="form-field-label padding-left-small" style="color: black">All fields are <span class="form-field-label-required italic-font">required</span></span>
				<div class="padding-top-large padding-left-small">
					<form [formGroup]="application3Form">
						<dx-validation-group #application3FormValidationGroup>
							<div class="col-lg-4 col-md-2 d-sm-none d-md-block"></div>
							<div class="row">
								<div class="col-lg-8 col-md-8">
									<div style="display: flex; flex-direction: row; align-items: center; padding-bottom: 10px">
										<span class="form-field-label">Citizenship Type</span>
									</div>
									<dx-radio-group style="margin-left: 5px" [items]="allCitizenshipTypeValues" [(value)]="selectedCitizenshipType" layout="horizontal" itemTemplate="radio">
										<div class="radio" *dxTemplate="let data of 'radio'">
											<p class="form-field-label-no-padding">
												<b style="font-weight: 500">{{ data }}</b>
											</p>
										</div>
									</dx-radio-group>
									<span style="margin-top: 5px" [ngClass]="selectedCitizenshipTypeErrorMessage == true ? 'error-message-visible' : 'error-message-hidden'"
										>Unfortunately, we’re unable to open an account for you at this time. Please visit a branch or contact support for help</span
									>
								</div>
							</div>
							<div class="row row-padding margin-negative">
								<div class="col-lg-4 col-md-4">
									<div class="form-label-wrapper">
										<span class="form-field-label">Social Security Number</span>
									</div>
									<dx-text-box
										validationMessageMode="always"
										class="form-field-margin-top"
										[appControlHeight]
										formControlName="socialSecurityNumber"
										label="Social Security Number"
										[stylingMode]="stylingMode"
										[labelMode]="labelMode"
										mask="000-00-0000"
										maskChar="-"
									>
										<dx-validator>
											<dxi-validation-rule type="required" message="Social security number is required"> </dxi-validation-rule>
										</dx-validator>
									</dx-text-box>
								</div>
								<div class="col-lg-4 col-md-4">
									<div class="form-label-wrapper">
										<span class="form-field-label">Date of Birth</span>
									</div>
									<dx-date-box
										validationMessageMode="always"
										class="form-field-margin-top"
										[appControlHeight]
										displayFormat="{{ dateTimeFormat }}"
										[useMaskBehavior]="true"
										formControlName="dOB"
										label="Date of Birth"
										[stylingMode]="stylingMode"
										[labelMode]="labelMode"
										type="date"
									>
										<dx-validator>
											<dxi-validation-rule type="required" message="Date of Birth is required"> </dxi-validation-rule>
										</dx-validator>
									</dx-date-box>
								</div>
							</div>
							<div class="row row-padding">
								<div class="col-lg-4 col-md-4">
									<div class="form-label-wrapper">
										<span class="form-field-label">City of Birth</span>
									</div>
									<dx-text-box
										class="form-field-margin-top"
										validationMessageMode="always"
										[appControlHeight]
										label="City of Birth"
										formControlName="birthCity"
										[stylingMode]="stylingMode"
										[labelMode]="labelMode"
									>
										<dx-validator>
											<dxi-validation-rule type="required" message="City of Birth is required"> </dxi-validation-rule>
										</dx-validator>
									</dx-text-box>
								</div>
								<div class="col-lg-4 col-md-4">
									<div class="form-label-wrapper">
										<span class="form-field-label">State of Birth</span>
									</div>
									<dx-text-box
										class="form-field-margin-top"
										validationMessageMode="always"
										[appControlHeight]
										label="State of Birth"
										formControlName="birthState"
										[stylingMode]="stylingMode"
										[labelMode]="labelMode"
									>
										<dx-validator>
											<dxi-validation-rule type="required" message="State of Birth is required"> </dxi-validation-rule>
										</dx-validator>
									</dx-text-box>
								</div>
								<div class="col-lg-4 col-md-4">
									<div class="form-label-wrapper">
										<span class="form-field-label">Country of Birth</span>
									</div>
									<dx-text-box
										validationMessageMode="always"
										class="form-field-margin-top"
										[appControlHeight]
										label="Country of Birth"
										formControlName="birthCountry"
										[stylingMode]="stylingMode"
										[labelMode]="labelMode"
									>
										<dx-validator>
											<dxi-validation-rule type="required" message="Country of Birth is required"> </dxi-validation-rule>
										</dx-validator>
									</dx-text-box>
								</div>
							</div>
							<div class="row row-padding-small">
								<div class="col-lg-8 col-md-8">
									<div class="form-label-wrapper">
										<span class="form-field-label">Employment Status</span>
									</div>
									<dx-radio-group style="margin-left: 5px" [items]="allEmploymentStatusValues" [(value)]="selectedEmploymentStatus" layout="horizontal" itemTemplate="radio">
										<div class="radio" *dxTemplate="let data of 'radio'">
											<p class="form-field-label-no-padding">
												<b style="font-weight: 500">{{ data }}</b>
											</p>
										</div>
									</dx-radio-group>
									<span style="margin-top: 5px" [ngClass]="selectedEmploymentStatusErrorMessage == true ? 'error-message-visible' : 'error-message-hidden'"
										>Employment status is required</span
									>
								</div>
							</div>
							<div class="row row-padding">
								<div *ngIf="selectedEmploymentStatus === 'Employed' || selectedEmploymentStatus === 'Self employed'" class="col-lg-4 col-md-4">
									<div class="form-label-wrapper">
										<span class="form-field-label">Occupation</span>
									</div>
									<dx-text-box
										class="form-field-margin-top"
										validationMessageMode="always"
										[appControlHeight]
										label="Occupation"
										formControlName="occupation"
										[stylingMode]="stylingMode"
										[labelMode]="labelMode"
									>
										<dx-validator>
											<dxi-validation-rule type="required" message="Occupation is required"> </dxi-validation-rule>
										</dx-validator>
									</dx-text-box>
								</div>
								<div *ngIf="selectedEmploymentStatus === 'Employed'" class="col-lg-4 col-md-4">
									<div class="form-label-wrapper">
										<span class="form-field-label">Employer</span>
									</div>
									<dx-text-box
										class="form-field-margin-top"
										validationMessageMode="always"
										[appControlHeight]
										label="Employer"
										formControlName="employer"
										[stylingMode]="stylingMode"
										[labelMode]="labelMode"
									>
										<dx-validator>
											<dxi-validation-rule type="required" message="Employer is required"> </dxi-validation-rule>
										</dx-validator>
									</dx-text-box>
								</div>
							</div>
						</dx-validation-group>
					</form>
				</div>
				<div class="padding-left-small" style="display: flex; flex-direction: column; align-items: baseline">
					<span class="form-field-label">Are you a senior foreign political official, or are you a business associate or an immediate family member of a foreign political official?</span>
					<div class="padding-top-small padding-left">
						<div class="form-label-wrapper">
							<div class="checkbox-container">
								<dx-check-box
									class="approve-content-wrapper-checkbox"
									[iconSize]="22"
									[(value)]="foreignDelegateY"
									(onValueChanged)="onForeignDelegateValueTrue($event)"
									[disabled]="!enableForeignDelegateY"
								>
								</dx-check-box>
								<span class="approve-content-wrapper">Yes</span>
							</div>
							<div class="padding-checkbox checkbox-container">
								<dx-check-box
									class="approve-content-wrapper-checkbox"
									[iconSize]="22"
									[(value)]="foreignDelegateN"
									(onValueChanged)="onForeignDelegateValueFalse($event)"
									[disabled]="!enableForeignDelegateN"
								>
								</dx-check-box>
								<span class="approve-content-wrapper">No</span>
								<span [ngClass]="foreignDelegateErrorMarker == true ? 'error-marker-visible' : 'error-marker-hidden'" (click)="showForeignDelegateErrorMessage()">! </span>
							</div>
						</div>

						<span style="margin-top: 5px" [ngClass]="foreignDelegateErrorMessage == true ? 'error-message-visible' : 'error-message-hidden'">Choose either Yes or No</span>
					</div>
				</div>
				<div class="padding-left-small" style="display: flex; flex-direction: column; align-items: baseline; padding-top: 20px">
					<span class="form-field-label">Would you like to receive a free debit card with your account?</span>
					<div class="padding-top-small padding-left">
						<div class="form-label-wrapper">
							<div>
								<dx-check-box
									class="approve-content-wrapper-checkbox"
									[(value)]="freeDebitCardY"
									(onValueChanged)="onFreeDebitCardValueTrue($event)"
									[disabled]="!enableFreeDebitCardY"
								></dx-check-box>
								<span class="approve-content-wrapper">Yes</span>
							</div>
							<div class="padding-checkbox" style="display: flex; flex-direction: row; align-items: baseline">
								<dx-check-box
									class="approve-content-wrapper-checkbox"
									[(value)]="freeDebitCardN"
									(onValueChanged)="onFreeDebitCardValueFalse($event)"
									[disabled]="!enableFreeDebitCardN"
								></dx-check-box>
								<span class="approve-content-wrapper">No</span>
								<span [ngClass]="freeDebitCardErrorMarker == true ? 'error-marker-visible' : 'error-marker-hidden'" (click)="showFreeDebitCardValidationMessage()">! </span>
							</div>
						</div>

						<span style="margin-top: 5px" [ngClass]="freeDebitCardErrorMessage == true ? 'error-message-visible' : 'error-message-hidden'">Choose either Yes or No</span>
					</div>
				</div>
				<div class="padding-left-small" style="display: flex; flex-direction: column; padding-top: 20px">
					<span class="form-field-label">Please open and read the following disclosures:</span>
					<ul class="padding-left padding-top-small">
						<li class="padding-bottom-half">
							<span class="disclosure-header" (click)="acknowledgeOverdraftProtectionDoc()">Overdraft Protection</span>
						</li>
						<li>
							<div style="padding-top: 10px; margin-bottom: 10px">
								<div style="display: flex; align-items: center; margin-bottom: 5px">
									<span class="approve-content-wrapper">I choose to:</span>
									<div style="display: flex; flex-direction: row; align-items: center">
										<dx-check-box
											[readOnly]="!allowOverdraftProjectionOptInUpdate"
											class="approve-content-wrapper-checkbox"
											[iconSize]="20"
											[(value)]="overdraftProtectionOptIn"
											(mouseover)="onOverdraftProtectionOptInMouseOver($event)"
											(mouseout)="onOverdraftProtectionOptInMouseOut($event)"
											(onValueChanged)="onOverdraftOptInChange($event)"
										></dx-check-box>
										<span class="approve-content-wrapper">Opt in</span>
									</div>
									<div style="display: flex; flex-direction: row; align-items: center">
										<dx-check-box
											[readOnly]="!allowOverdraftProjectionOptOutUpdate"
											class="approve-content-wrapper-checkbox"
											[iconSize]="20"
											(mouseover)="onOverdraftProtectionOptInMouseOver($event)"
											(mouseout)="onOverdraftProtectionOptInMouseOut($event)"
											[(value)]="overdraftProtectionOptOut"
											(onValueChanged)="onOverdraftOptOutChange($event)"
										></dx-check-box>
										<span class="approve-content-wrapper">Opt out</span>
									</div>
								</div>
								<span [ngClass]="overDraftProtectionDisclosureStyleClass">{{ overDraftProtectionMessage }}</span>
							</div>
						</li>
						<li>
							<span class="disclosure-header" (click)="acknowledgeRegulationEDoc()">Regulation E</span>
						</li>
						<li>
							<div style="padding-top: 10px; margin-bottom: 10px">
								<div style="display: flex; align-items: center; padding-bottom: 10px">
									<dx-check-box
										[readOnly]="!allowRegulationYNUpdate"
										class="approve-content-wrapper-checkbox"
										[iconSize]="20"
										(mouseover)="onRegulationEMouseOver($event)"
										(mouseout)="onRegulationEMoverOut($event)"
										[(value)]="regulationYN"
										(onValueChanged)="onRegulationEDisclosureChange($event)"
									></dx-check-box>
									<span class="approve-content-wrapper">I have read and agree to the above disclosure</span>
								</div>
								<span [ngClass]="regulationEStyleClass">{{ regulationEMessage }}</span>
							</div>
						</li>
						<li>
							<span class="disclosure-header" (click)="acknowledgeDisclosureAgreementDoc()">E signature and Electronic Disclosure Agreement</span>
						</li>
						<li>
							<div style="padding-top: 10px; margin-bottom: 10px">
								<div style="display: flex; align-items: center; padding-bottom: 10px">
									<dx-check-box
										[readOnly]="!allowDisclosureAgreementYNUpdate"
										class="approve-content-wrapper-checkbox"
										[iconSize]="20"
										(mouseover)="onESignatureDisclosureMouseOver($event)"
										(mouseout)="onEsignatureDisclosureMouseOut($event)"
										[(value)]="disclosureAgreementYN"
										(onValueChanged)="onElectronicDisclosureChange($event)"
									></dx-check-box>
									<span class="approve-content-wrapper">I have read and agree to the above disclosure</span>
								</div>
								<span [ngClass]="eSignatureStyleClass">{{ eSignatureMessage }}</span>
							</div>
						</li>
						<li>
							<span class="disclosure-header" (click)="acknowledgePrivacyPolicyDoc()">Privacy Policy and Combined Disclosures</span>
						</li>
						<li>
							<div style="padding-top: 10px; margin-bottom: 10px">
								<div style="display: flex; align-items: center; padding-bottom: 10px">
									<dx-check-box
										[readOnly]="!allowUpdateCombinedDisclosureYN"
										class="approve-content-wrapper-checkbox"
										[iconSize]="20"
										(mouseover)="onCombinedDisclosureMouseOver($event)"
										(mouseout)="onCombinedDisclosureMouseOut($event)"
										[(value)]="combinedDisclosureYN"
										(onValueChanged)="onPrivacyPolicyDisclosureChange($event)"
									></dx-check-box>
									<span class="approve-content-wrapper">I have read and agree to the above disclosure</span>
								</div>
								<span [ngClass]="combinedDisclosureStyleClass">{{ combinedDisclosureMessage }}</span>
							</div>
						</li>
					</ul>
				</div>
			</section>
		</div>
		<div class="row footer" style="padding-top: 10px; padding-right: 10px">
			<div *ngIf="fullFooterLayout" style="display: flex; flex-direction: row; justify-content: space-between">
				<div style="display: flex; flex-direction: row; align-items: center">
					<img src="../../../assets/images/lock_not_filled.svg" height="10vh" />
					<span style="font-size: 10px; color: lightgray"> Your security matters to us</span>
				</div>
				<img src="../../../assets/images/logo_watermark.png" height="30vh" />
				<button mat-button style="border: 2px solid; border-radius: 10px; background: transparent; font-size: 15px; width: 100px" (click)="navigateToApplicationSummary()">Next</button>
			</div>
			<div *ngIf="trimmedFooter" style="display: flex; flex-direction: row; justify-content: center">
				<button mat-button style="border: 2px solid; border-radius: 10px; background: transparent; font-size: 15px; width: 100px" (click)="navigateToApplicationSummary()">Next</button>
			</div>
		</div>
	</div>
</div>
