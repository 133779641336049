<div *ngIf="navigationState.activeScreen === 'Dashboard'" class="col-lg-12 col-md-12 application-container">
	<div class="app-detail-container">
		<div class="row col-lg-12 col-md-12" style="display: flex; height: inherit">
			<div class="col-lg-6 col-md-6 background-image-cover" style="background-image: url('./../../../../assets/images/bank-image.png')">
				<div class="header-image-container">
					<img class="header-image" src="../../../assets/images/Trb_Bank_Logo_with_caption.png" />
				</div>
				<div class="row" style="height: inherit; margin-top: 10%; text-align: center">
					<ul style="display: flex; text-align: center; flex-direction: column; align-items: center">
						<li style="padding-bottom: 20px">
							<div class="dashboard-header-font-size" style="margin-right: 45px">Let's</div>
						</li>
						<li style="padding-bottom: 20px">
							<div class="dashboard-header-font-size default-margin-right">Get</div>
						</li>
						<li><div class="dashboard-header-font-size padding-default">Started</div></li>
					</ul>
				</div>
				<div style="padding-left: 10px; padding-bottom: 10px">
					<div style="display: flex; flex-direction: row; justify-content: space-between">
						<img src="../../../assets/images/logo_watermark.png" height="30vh" />
						<div style="display: flex; flex-direction: row; align-items: center">
							<img src="../../../assets/images/lock_not_filled.svg" height="10vh" />
							<span style="font-size: 10px; color: grey"> Your security matters to us</span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6 col-md-6 dashboard-content-container">
				<div class="row header dashboard-default-padding">
					<ul style="display: flex; text-align: center; flex-direction: column; align-items: center">
						<li><div style="font-size: 27px; color: #8b7054; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif">Open your new</div></li>
						<li>
							<div style="font-size: 27px; color: #8b7054; font-weight: 500; padding-top: 15px; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif">TRB Debit Card Account</div>
						</li>
					</ul>
				</div>
				<div class="row body">
					<div class="dashboard-button-container">
						<button
							mat-raised-button
							(click)="navigateToNewCustomerScreen()"
							(mouseover)="onMouseOverOnNewCustomerButton()"
							(mouseout)="onMouseOutOnNewCustomerButton()"
							[ngClass]="newCustomerBoxContainerStyle"
						>
							<div class="background-image"></div>
							<div class="button-content-container">
								<ul style="margin-right: 30px">
									<li class="box-container-header">New</li>
									<div style="display: flex; flex-direction: column; align-items: center">
										<div class="box-container-body-title">Texas Regional Bank</div>
										<div class="box-container-body-content">Customer</div>
									</div>
								</ul>
								<span class="span-hyperlink" (click)="navigateToNewCustomerScreen()">Click here </span>
							</div>
						</button>
						<button
							mat-raised-button
							(click)="navigateToEditApplication()"
							(mouseover)="onMouseOverOnExistingCustomerButton()"
							(mouseout)="onMouseOutOnExistingCustomerButton()"
							[ngClass]="existingCustomerBoxContainerStyle"
						>
							<div class="background-image"></div>
							<div class="button-content-container">
								<ul style="margin-right: 30px">
									<li class="box-container-header">Existing</li>
									<div style="display: flex; flex-direction: column; align-items: center">
										<div class="box-container-body-title">Texas Regional Bank</div>
										<div class="box-container-body-content">Customer</div>
									</div>
								</ul>
								<span class="span-hyperlink" (click)="navigateToEditApplication()">Click here </span>
							</div>
						</button>
					</div>
				</div>
				<div class="row footer"></div>
			</div>
		</div>
	</div>
</div>
<app-new-customer-acknowledgement *ngIf="navigationState.activeScreen === 'NewCustomer'" style="height: 100%" (onBackBtnPressed)="navigateToDashboard()"></app-new-customer-acknowledgement>
