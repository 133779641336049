import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { S3FileService } from '../../../../../../../goldstar-share/src/app/services/s3file-service';
import { DebitCardApplicationDataStore } from '../../services/debit-card-application-data.store';
import { DebitCardApplicationService } from '../../services/debit-card-application.service';
import { MasterDataService } from '../../services/master-data.service';
import { SpinnerState, DebitCardApplicationStage, DebitCardApplicationStatus } from '../../models/model';
import { BaseApplicationComponent } from '../base-application/base-application.component';
import { ErrorHandlingService } from '../../../../../../../goldstar-share/src/app/services/error-handling.service';

@Component({
	selector: 'app-debit-card-application-authorization',
	templateUrl: './debit-card-application-authorization.component.html',
	styleUrls: ['./debit-card-application-authorization.component.scss'],
})
export class DebitCardApplicationAuthorizationComponent extends BaseApplicationComponent implements OnInit {
	acceptedTermsAndCondition: boolean | null | undefined = false;
	showTermsAndConditionValidation: boolean = false;

	// This is for full footer (all 3 columns)
	fullFooterLayout: boolean = true;

	// This is one column (only continue button)
	trimmedFooter: boolean = false;

	@HostListener('window:load', ['$event'])
	onLoad() {
		if (window.innerWidth <= 500) {
			this.fullFooterLayout = false;
			this.trimmedFooter = true;
		} else {
			this.fullFooterLayout = true;
			this.trimmedFooter = false;
		}
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize(event: any) {
		if (window.innerWidth <= 500) {
			this.fullFooterLayout = false;
			this.trimmedFooter = true;
		} else {
			this.fullFooterLayout = true;
			this.trimmedFooter = false;
		}
	}

	constructor(
		formBuilder: UntypedFormBuilder,
		activatedRoute: ActivatedRoute,
		errorHandlingService: ErrorHandlingService,
		spinnerService: NgxSpinnerService,
		router: Router,
		debitCardApplicationService: DebitCardApplicationService,
		store: DebitCardApplicationDataStore,
		masterDataService: MasterDataService,
		private fileService: S3FileService,
		private dialog: MatDialog
	) {
		super(formBuilder, errorHandlingService, spinnerService, store, router, activatedRoute, debitCardApplicationService, masterDataService);
	}

	async ngOnInit(): Promise<void> {}

	handleValueChange(event: any) {
		if (this.acceptedTermsAndCondition) {
			this.showTermsAndConditionValidation = false;
		}
	}

	navigateBackToApplication3() {
		this.routeSelector('debitcardppplication/applicationSummary');
	}

	toggleSpinner(state: SpinnerState) {
		switch (state) {
			case SpinnerState.Show:
				this.spinnerService.show();
				this.showSpinner = true;
				break;
			case SpinnerState.Hide:
			default:
				this.spinnerService.hide();
				this.showSpinner = false;
				break;
		}
	}

	async navigateToApplicationSubmitted() {
		if (this.acceptedTermsAndCondition) {
			this.showTermsAndConditionValidation = false;
			this.toggleSpinner(SpinnerState.Show);
			await this.store.updateStatus(DebitCardApplicationStatus.PEN_REVIEW);
			await this.store
				.push()
				.then((response) => {
					this.toggleSpinner(SpinnerState.Hide);
					this.errorHandlingService.showSuccessMessage('Successfully saved application');
					this.routeSelector('debitcardppplication/submitted');
				})
				.catch((error) => {
					this.toggleSpinner(SpinnerState.Hide);
					this.errorHandlingService.showErrorMessage('Failed to save application');
				});
		} else {
			this.showTermsAndConditionValidation = true;
		}
	}
}
