<div class="widget-container" style="overflow: hidden; margin-top: 15px; height: 102%">
	<div class="app-detail-container">
		<div class="row col-lg-12 col-md-12" style="margin-right: 0.1%; display: flex; flex-direction: row; height: 100%">
			<div
				class="col-lg-6 col-md-6 dashboard-start"
				style="background-image: url('./../../../../assets/images/bank-image.png'); background-repeat: no-repeat; display: flex; flex-direction: column"
			>
				<div style="display: flex; text-align: center; flex-direction: column; padding-top: 20px">
					<img style="margin-left: auto; margin-right: auto" src="../../../assets/images/Trb_Bank_Logo_with_caption.png" height="100vh" width="620vh" />
				</div>
				<div class="row" style="height: inherit; margin-top: 20%; text-align: center">
					<ul style="display: flex; text-align: center; flex-direction: column; align-items: center">
						<li style="padding-bottom: 20px">
							<div style="margin-right: 45px; font-size: 60px; padding: 20px; color: #002b5c; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif">Let's</div>
						</li>
						<li style="padding-bottom: 20px">
							<div style="margin-right: 75px; font-size: 60px; padding: 20px; color: #002b5c; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif">Get</div>
						</li>
						<li><div style="font-size: 60px; padding: 20px; padding-left: 40px; color: #002b5c; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif">Started</div></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-6 col-md-6" style="background-color: white; height: 100%; display: flex; flex-direction: column; justify-content: space-between">
				<div class="row header" style="padding-top: 50px">
					<ul style="display: flex; text-align: center; flex-direction: column; align-items: center">
						<li>
							<div style="font-size: 27px; color: #8b7054; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif">
								I am an <span style="font-weight: 500; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif">existing </span>Texas Regional Bank customer
							</div>
						</li>
					</ul>
				</div>

				<div class="row body" style="margin-bottom: 90px; padding-left: 30px">
					<div class="col-lg-4 col-md-2 d-sm-none d-md-block"></div>
					<div style="color: #002b5c; padding-bottom: 40px; font-size: 25px; margin-left: 5px">Please enter your:</div>
					<div class="row row-padding">
						<div class="col-lg-8 col-md-8">
							<div style="display: flex; flex-direction: row; align-items: center">
								<span class="form-field-label">Social Security Number</span>
							</div>
							<dx-text-box class="form-field-margin-top" height="50px" label="First" [stylingMode]="stylingMode" [labelMode]="labelMode"> </dx-text-box>
						</div>
					</div>
					<div class="row row-padding">
						<div class="col-lg-8 col-md-8">
							<div style="display: flex; flex-direction: row; align-items: center">
								<span class="form-field-label">Existing Account Number</span>
							</div>
							<dx-text-box class="form-field-margin-top" height="50px" label="First" [stylingMode]="stylingMode" [labelMode]="labelMode"> </dx-text-box>
						</div>
					</div>
					<div class="row footer"></div>
				</div>
				<div class="row footer" style="padding-bottom: 10px">
					<div style="display: flex; flex-direction: row; justify-content: space-between; padding-right: 40px; padding-left: 40px">
						<button mat-button style="border: 2px solid; border-radius: 10px; background: transparent; font-size: 15px; width: 100px" (click)="navigateToDashboard()">Back</button>
						<button mat-button style="border: 2px solid; border-radius: 10px; background: transparent; font-size: 15px; width: 100px">Continue</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
