<ngx-spinner *ngIf="showSpinner" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
	<p style="color: white">Loading...</p>
</ngx-spinner>
<div [fluidHeight] class="row col-lg-12 col-md-12 application-container">
	<div class="header" style="height: inherit; display: flex; flex-direction: column; justify-content: space-between">
		<div class="row col-lg-12 col-md-12 custom-row" style="padding-top: 50px">
			<div class="header-image-container">
				<img class="header-image" src="../../../assets/images/Trb_Bank_Logo_with_caption.png" />
			</div>
		</div>
		<div style="overflow-y: auto; overflow-x: hidden; padding-top: 50px">
			<section>
				<h1 class="header-title" style="padding-left: 3px; color: #8b7054">Let's get to know you</h1>
			</section>
			<section class="application-section-container">
				<h1 class="sub-header-title" style="color: #002b5c">Application - Section 1</h1>
				<span class="form-field-label" style="color: black">Enter your name as it appears on your ID <span class="form-field-label-required italic-font">(required)</span></span>
				<div style="padding-top: 2px">
					<form [formGroup]="application1Form">
						<dx-validation-group #application1FormValidationGroup>
							<div class="col-lg-4 col-md-2 d-sm-none d-md-block"></div>
							<div class="row row-padding">
								<div class="col-lg-8 col-md-8">
									<dx-text-box
										[appControlHeight]
										class="form-field-margin-top"
										validationMessageMode="always"
										label="First"
										formControlName="fullName"
										[stylingMode]="stylingMode"
										[labelMode]="labelMode"
									>
										<dx-validator>
											<dxi-validation-rule type="required" message="Full Name is required"> </dxi-validation-rule>
										</dx-validator>
									</dx-text-box>
								</div>
							</div>
							<div class="row row-padding">
								<div class="col-lg-4 col-md-4">
									<div style="display: flex; flex-direction: row; align-items: center">
										<span class="form-field-label">Cell Phone Number</span>
										<span class="form-field-label-required italic-font">(required)</span>
									</div>
									<div style="display: flex">
										<dx-select-box
											[appControlHeight]
											width="150px"
											validationMessageMode="always"
											style="margin-top: 10px"
											[items]="allStates"
											displayExpr="countryFullName"
											valueExpr="countryFullName"
											formControlName="countryCode"
											label="Country Code"
											[stylingMode]="stylingMode"
											[labelMode]="labelMode"
											[searchEnabled]="true"
											searchExpr="countryFullName"
											searchMode="contains"
											(onValueChanged)="onValueChanged($event)"
										>
											<div *dxTemplate="let data of 'item'">
												<div style="display: flex; flex-direction: row; align-items: center">
													<img *ngIf="data.countryFullName === 'USA'" height="15" width="15" src="../../../../../assets/images/american.png" />
													<img *ngIf="data.countryFullName === 'MX'" height="15" width="15" src="../../../../../assets/images/mexico.png" />
													<img *ngIf="data.countryFullName === 'CA'" height="15" width="15" src="../../../../../assets/images/canada_flag.png" />
													<img *ngIf="data.countryFullName === 'IN'" height="15" width="15" src="../../../../../assets/images/india.png" />
													<div style="display: inline-block; margin-left: 5px">{{ data.countryFullName }}</div>
												</div>
											</div>
											<dx-validator>
												<dxi-validation-rule type="required" message="Country code is required"> </dxi-validation-rule>
											</dx-validator>
										</dx-select-box>
										<dx-text-box
											[appControlHeight]
											*ngIf="isUsaCountryCodeSelected"
											validationMessageMode="always"
											width="100%"
											class="form-field-margin-top"
											height="50px"
											label="Cell Phone Number"
											formControlName="cellPhoneNumber"
											[stylingMode]="stylingMode"
											[labelMode]="labelMode"
											mask="000 000-0000"
											maskChar="-"
										>
											<dx-validator>
												<dxi-validation-rule type="required" message="Phone number is required"> </dxi-validation-rule>
											</dx-validator>
										</dx-text-box>
										<dx-text-box
											[appControlHeight]
											*ngIf="isCanadaCountryCodeSelected"
											validationMessageMode="always"
											width="100%"
											class="form-field-margin-top"
											height="50px"
											label="Cell Phone Number"
											formControlName="cellPhoneNumber"
											[stylingMode]="stylingMode"
											[labelMode]="labelMode"
											mask="000-000-0000"
											maskChar="-"
										>
											<dx-validator>
												<dxi-validation-rule type="required" message="Phone number is required"> </dxi-validation-rule>
											</dx-validator>
										</dx-text-box>
										<dx-text-box
											[appControlHeight]
											*ngIf="isMexicoCountryCodeSelected"
											validationMessageMode="always"
											width="100%"
											class="form-field-margin-top"
											height="50px"
											label="Cell Phone Number"
											formControlName="cellPhoneNumber"
											[stylingMode]="stylingMode"
											[labelMode]="labelMode"
											mask="00-0000-0000"
											maskChar="-"
										>
											<dx-validator>
												<dxi-validation-rule type="required" message="Phone number is required"> </dxi-validation-rule>
											</dx-validator>
										</dx-text-box>
										<dx-number-box
											[appControlHeight]
											*ngIf="isIndiaCountryCodeSelected"
											validationMessageMode="always"
											width="100%"
											class="form-field-margin-top"
											height="50px"
											label="Cell Phone Number"
											formControlName="cellPhoneNumber"
											[stylingMode]="stylingMode"
											[labelMode]="labelMode"
										>
											<dx-validator>
												<dxi-validation-rule type="required" message="Phone number is required"> </dxi-validation-rule>
											</dx-validator>
										</dx-number-box>
									</div>
								</div>
								<div class="col-lg-4 col-md-4 row-section-padding">
									<div style="display: flex; flex-direction: row; align-items: center">
										<span class="form-field-label">Email Address</span>
										<span class="form-field-label-required italic-font">(required)</span>
									</div>
									<dx-text-box
										[appControlHeight]
										class="form-field-margin-top"
										validationMessageMode="always"
										label="Email Address"
										formControlName="emailAddress"
										[stylingMode]="stylingMode"
										[labelMode]="labelMode"
									>
										<dx-validator>
											<dxi-validation-rule type="required" message="Email is required"> </dxi-validation-rule>
											<dxi-validation-rule type="email" message="Provide a valid email id"></dxi-validation-rule>
										</dx-validator>
									</dx-text-box>
								</div>
							</div>
						</dx-validation-group>
					</form>
				</div>
			</section>
		</div>
		<div class="row footer" style="padding-top: 10px; padding-right: 10px">
			<div *ngIf="fullFooterLayout" style="display: flex; flex-direction: row; justify-content: space-between">
				<div style="display: flex; flex-direction: row; align-items: center">
					<img src="../../../assets/images/lock_not_filled.svg" height="10vh" />
					<span style="font-size: 10px; color: lightgray"> Your security matters to us</span>
				</div>
				<img src="../../../assets/images/logo_watermark.png" height="30vh" />
				<button mat-button class="rounded-btn-style" (click)="navigateToPhoneVerification()">
					<span class="btn-title">Continue to verify phone</span>
				</button>
			</div>
			<div *ngIf="trimmedFooter" style="display: flex; flex-direction: row; justify-content: center">
				<button mat-button class="rounded-btn-style" (click)="navigateToPhoneVerification()">
					<span class="btn-title">Continue to verify phone</span>
				</button>
			</div>
		</div>
	</div>
</div>
