import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorHandlingService } from '../../../../../../../goldstar-share/src/app/services/error-handling.service';
import { S3FileService } from '../../../../../../../goldstar-share/src/app/services/s3file-service';
import { DebitCardApplicationDataStore } from '../../services/debit-card-application-data.store';
import { DebitCardApplicationService } from '../../services/debit-card-application.service';
import { MasterDataService } from '../../services/master-data.service';
import { DxValidationGroupComponent } from 'devextreme-angular';
import { DebitCardApplicationInfo, DebitCardApplicationStatus, SpinnerState } from '../../models/model';
import { DebitCardApplication, PlaidIdentityInfoRequest, State, UserIdentityVerificationRequest } from '../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { v4 as uuidv4 } from 'uuid';
import { PlaidComponentConfig, UserIdentityVerificationStatus, PlaidUserConfig } from '../../../../../../../goldstar-share/src/app/models/models';
import { BaseApplicationComponent } from '../base-application/base-application.component';
import { environment } from '../../../../../../../goldstar-client/src/environments/environment';
import { ApplicationTestService } from '../../../../../../../goldstar-client/src/app/services/applicationTestService';

@Component({
	selector: 'app-debit-card-application-summary',
	templateUrl: './debit-card-application-summary.component.html',
	styleUrls: ['./debit-card-application-summary.component.scss'],
})
export class DebitCardApplicationSummaryComponent extends BaseApplicationComponent implements OnInit {
	applicationSummaryForm!: UntypedFormGroup;
	public plaidConfig!: PlaidComponentConfig;
	plaidContainerStyle!: string;
	selectedDebitCardApplication!: DebitCardApplication;
	identityVerificationHeader!: string;
	loggedInUser!: string;
	userIdentityVerified!: boolean;

	plaidVerificationTemplateId!: string;

	// This is for full footer (all 3 columns)
	fullFooterLayout: boolean = true;

	// This is one column (only continue button)
	trimmedFooter: boolean = false;

	@HostListener('window:load', ['$event'])
	onLoad() {
		if (window.innerWidth <= 500) {
			this.fullFooterLayout = false;
			this.trimmedFooter = true;
		} else {
			this.fullFooterLayout = true;
			this.trimmedFooter = false;
		}
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize(event: any) {
		if (window.innerWidth <= 500) {
			this.fullFooterLayout = false;
			this.trimmedFooter = true;
		} else {
			this.fullFooterLayout = true;
			this.trimmedFooter = false;
		}
	}

	@ViewChild('applicationSummaryFormValidationGroup', { static: false }) applicationSummaryFormValidationGroup!: DxValidationGroupComponent;
	constructor(
		formBuilder: UntypedFormBuilder,
		activatedRoute: ActivatedRoute,
		errorHandlingService: ErrorHandlingService,
		spinnerService: NgxSpinnerService,
		router: Router,
		debitCardApplicationService: DebitCardApplicationService,
		store: DebitCardApplicationDataStore,
		masterDataService: MasterDataService,
		private fileService: S3FileService,
		private dialog: MatDialog,
		private appTestService: ApplicationTestService
	) {
		super(formBuilder, errorHandlingService, spinnerService, store, router, activatedRoute, debitCardApplicationService, masterDataService);
		const verificationTemplateId = environment.plaidVerificationTemplate;
		this.plaidVerificationTemplateId = verificationTemplateId;
		console.log('The template id used for identity verification is ----', verificationTemplateId);
	}

	async ngOnInit(): Promise<void> {
		this.initializeForm();
		if (this.selectedDebitCardApplication.identityVerifiedYN) {
			this.userIdentityVerified = true;
			this.plaidContainerStyle = 'plaid-link';
		} else {
			this.loggedInUser = uuidv4();
			this.plaidContainerStyle = 'plaid-link';
			this.identityVerificationHeader = 'Preparing for identity verification';
			this.userIdentityVerified = false;
			const plaidSampler = this;
			this.plaidConfig = {
				onPlaidLinkReady() {
					return () => {};
				},
				beforeCreatingLinkToken() {
					return plaidSampler.createPlaidUserConfig();
				},
				beforeIdentityVerificationCreate() {
					const userIdentityInfo = plaidSampler.onPlaidCreateIdentityToken();
					console.log('Creating identity verification with data ---', userIdentityInfo);
					return userIdentityInfo;
				},
				beforeFetchingUserVerificationList() {
					return plaidSampler.createPlaidUserConfig();
				},
				onUserIdentityVerification(status: UserIdentityVerificationStatus) {
					console.log('So the status of verification is ----', status);
				},
				beforeIdentityVerificationRetry() {
					return () => {};
				},
				onUserIdentityVerificationRetry() {
					return () => {};
				},
			};
		}
	}

	initializeForm() {
		this.applicationSummaryForm = this.formBuilder.group({
			identityVerifiCationYN: [null],
			termsAndConditionYN: [null],
		});
	}

	createPlaidUserConfig(): PlaidUserConfig {
		const plaidUserConfig: PlaidUserConfig = {
			user_Id: this.loggedInUser ?? 'da38fba7-cc4f-4567-84b8-f13d39566112',
		};
		console.log('Plaid user id used for verification ----', plaidUserConfig.user_Id);
		return plaidUserConfig;
	}

	onPlaidCreateIdentityToken(): UserIdentityVerificationRequest {
		let userIdentityVerificationCreateRequest: UserIdentityVerificationRequest = {
			userIdentityInfo: {
				countryCode: 'US',
				userId: this.loggedInUser ?? 'da38fba7-cc4f-4567-84b8-f13d39566112',
				userName: {
					given_name: this.selectedDebitCardApplication.firstName ?? '',
					family_name: this.selectedDebitCardApplication.lastName ?? '',
				},
				dateOfBirth: this.selectedDebitCardApplication.dob ?? '',
				phoneNumber: `${this.selectedDebitCardApplication.countryCode}${this.selectedDebitCardApplication.primaryPhone}`,
				userIdentityNumber: {
					licenseType: this.getUserIdentificationDocType(this.selectedDebitCardApplication.countryLabel),
					value: this.selectedDebitCardApplication.ssn ?? '',
				},
				userAddress: {
					street: this.selectedDebitCardApplication.addressLine ?? '',
					city: this.selectedDebitCardApplication.city ?? '',
					postalCode: this.selectedDebitCardApplication.zipCode ?? '',
					region: this.selectedDebitCardApplication.stateShortLabel ?? '',
					country: this.selectedDebitCardApplication.countryLabel ?? '',
				},
				userEmail: this.selectedDebitCardApplication.primaryEmail ?? '',
			},
		};
		return userIdentityVerificationCreateRequest;
	}

	toggleSpinner(state: SpinnerState) {
		switch (state) {
			case SpinnerState.Show:
				this.spinnerService.show();
				this.showSpinner = true;
				break;
			case SpinnerState.Hide:
			default:
				this.spinnerService.hide();
				this.showSpinner = false;
				break;
		}
	}

	async onPlaidOutput(item: number, output: any) {
		switch (item) {
			case 1:
				if (output.event === 'IDENTITY_VERIFICATION_CLOSE_UI') {
					console.log('Data object obtained from plaid is -----', output);
					this.plaidContainerStyle = 'plaid-link-visible';
					this.identityVerificationHeader = 'Preparing for identity verification';
					this.toggleSpinner(SpinnerState.Show);
					const plaidInfoRequest: PlaidIdentityInfoRequest = {
						name: this.selectedDebitCardApplication.firstName ?? '',
						address: this.selectedDebitCardApplication.addressLine ?? '',
						primaryEmail: this.selectedDebitCardApplication.primaryEmail ?? '',
						dob: this.selectedDebitCardApplication.dob ?? '',
						identityInfo: output,
						primaryPhone: this.selectedDebitCardApplication.primaryPhone ?? '',
						userVerifiedYN: output?.data?.status === 'success' ? true : false,
					};
					//
					if (output && output.data) {
						if (output.data.documentary_verification && output.data.documentary_verification.status) plaidInfoRequest.plaidDocumentStatus = output.data.documentary_verification.status;
						if (output.data.selfie_check && output.data.selfie_check.status) plaidInfoRequest.plaidSelfieStatus = output.data.selfie_check.status;
						if (output.data.risk_check) {
							if (output.data.risk_check.status) plaidInfoRequest.riskCheckStatus = output.data.risk_check.status;
							if (output.data.risk_check.phone && output.data.risk_check.phone.linked_services)
								plaidInfoRequest.phoneLinkedServices = output.data.risk_check.phone.linked_services.join(',');
							if (output.data.risk_check.devices) {
								if (output.data.risk_check.devices[0].ip_proxy_type) plaidInfoRequest.ipProxy = output.data.risk_check.devices[0].ip_proxy_type;
								if (output.data.risk_check.devices[0].ip_spam_list_count) plaidInfoRequest.spamListCount = output.data.risk_check.devices[0].ip_spam_list_count;
							}
							if (output.data.risk_check.identity_abuse_signals) {
								if (output.data.risk_check.identity_abuse_signals.stolen_identity && output.data.risk_check.identity_abuse_signals.stolen_identity.score)
									plaidInfoRequest.stolenIdRiskScore = output.data.risk_check.identity_abuse_signals.stolen_identity.score;
								if (output.data.risk_check.identity_abuse_signals.synthetic_identity && output.data.risk_check.identity_abuse_signals.synthetic_identity.score)
									plaidInfoRequest.syntheticIdRiskScore = output.data.risk_check.identity_abuse_signals.synthetic_identity.score;
							}
							if (output.data.risk_check.email) {
								if (output.data.risk_check.email.breach_count) plaidInfoRequest.emailBreachCount = output.data.risk_check.email.breach_count;
								if (output.data.risk_check.email.domain_is_custom) plaidInfoRequest.emailDomainIsCustom = output.data.risk_check.email.domain_is_custom;
								if (output.data.risk_check.email.domain_is_disposable) plaidInfoRequest.emailDomainIsDisposable = output.data.risk_check.email.domain_is_disposable;
								if (output.data.risk_check.email.domain_is_free_provider) plaidInfoRequest.emailDomainIsFreeProvider = output.data.risk_check.email.domain_is_free_provider;
								if (output.data.risk_check.email.domain_registered_at) plaidInfoRequest.emailDomainRegisteredAt = output.data.risk_check.email.domain_registered_at;
								if (output.data.risk_check.email.is_deliverable) plaidInfoRequest.emailIsDeliverable = output.data.risk_check.email.is_deliverable;
								if (output.data.risk_check.email.last_breached_at) plaidInfoRequest.emailLastBreachedAt = output.data.risk_check.email.last_breached_at;
								if (output.data.risk_check.email.first_breached_at) plaidInfoRequest.emailFirstBreachedAt = output.data.risk_check.email.first_breached_at;
								if (output.data.risk_check.email.linked_services) plaidInfoRequest.emailLinkedServices = output.data.risk_check.email.linked_services.join(',');
								if (output.data.risk_check.email.top_level_domain_is_suspicious)
									plaidInfoRequest.emailTopLevelDomainSuspicious = output.data.risk_check.email.top_level_domain_is_suspicious;
							}
						}
						if (output.data.kyc_check) {
							if (output.data.kyc_check.status) plaidInfoRequest.kycStatus = output.data.kyc_check.status;
							if (output.data.kyc_check.address) {
								if (output.data.kyc_check.address.po_box) plaidInfoRequest.kycAddressPoBox = output.data.kyc_check.address.po_box;
								if (output.data.kyc_check.address.summary) plaidInfoRequest.kycAddressMatch = output.data.kyc_check.address.summary;
								if (output.data.kyc_check.address.type) plaidInfoRequest.kycAddressType = output.data.kyc_check.address.type;
							}
							if (output.data.kyc_check.date_of_birth && output.data.kyc_check.date_of_birth.summary) plaidInfoRequest.kycDOBMatch = output.data.kyc_check.date_of_birth.summary;
							if (output.data.kyc_check.id_number && output.data.kyc_check.id_number.summary) plaidInfoRequest.kycIdNumberMatch = output.data.kyc_check.id_number.summary;
							if (output.data.kyc_check.name && output.data.kyc_check.name.summary) plaidInfoRequest.kycNameMatch = output.data.kyc_check.name.summary;
							if (output.data.kyc_check.phone_number) {
								if (output.data.kyc_check.phone_number.area_code) plaidInfoRequest.kycPhoneNumberAreaCodeMatch = output.data.kyc_check.phone_number.area_code;
								if (output.data.kyc_check.phone_number.summary) plaidInfoRequest.kycPhoneNumberMatch = output.data.kyc_check.phone_number.summary;
							}
						}
						if (output.data.status) plaidInfoRequest.plaidStatus = output.data.status;
						if (output.data.user && output.data.user.ip_address) plaidInfoRequest.plaidDetectedIPAddress = output.data.user.ip_address;
					}
					//
					await this.debitCardApplicationService
						.persistUserIdentityInfo(plaidInfoRequest)
						.then(async (response) => {
							const applicationStatus =
								output?.data?.status === 'success' ? DebitCardApplicationStatus.IN_PROCESS_AUTHORIZATION : DebitCardApplicationStatus.IN_PROCESS_IDENTITY_VERIFICATION;
							await this.store.updateStatus(applicationStatus);
							const updateDebitCardApplication: DebitCardApplicationInfo = {
								userIdentityInfoGUID: response,
								identityVerifiedYN: plaidInfoRequest.userVerifiedYN,
							};
							let userIdentityInfoGUID: string = response;
							await this.store.patchValues(updateDebitCardApplication);
							await this.store
								.push()
								.then(async (response) => {
									//
									try {
										await this.appTestService.runAllTests(output.data.id, userIdentityInfoGUID, response, output);
									} catch (e) {
										console.log(e);
									}
									//
									this.toggleSpinner(SpinnerState.Hide);
									if (output?.data?.status === 'success') {
										this.userIdentityVerified = true;
										this.errorHandlingService.showSuccessMessage('Successfully saved application');
										this.routeSelector('debitcardppplication/authorization');
									} else {
										this.routeSelector('debitcardppplication/identityVerificationFailed');
									}
								})
								.catch((error) => {
									this.toggleSpinner(SpinnerState.Hide);
									this.errorHandlingService.showErrorMessage('Failed to save application');
								});
						})
						.catch((error) => {
							this.toggleSpinner(SpinnerState.Hide);
							this.errorHandlingService.showErrorMessage('Error saving user identity info in database');
						});
				}
				break;
			default:
				break;
		}
	}

	navigateToAuthorization() {
		this.routeSelector('debitcardppplication/authorization');
	}
	navigateBackToApplication3() {
		this.routeSelector('debitcardppplication/application3');
	}

	getUserIdentificationDocType(countryShortLabel: string | null | undefined): string {
		switch (countryShortLabel) {
			case 'US':
				return 'us_ssn';
			case 'CA':
				return 'CaSin';
			case 'MX':
				return 'MxCurp';
			default:
				return 'us_ssn';
		}
	}
}
