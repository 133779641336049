import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/header/header.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoanApplicationHeaderComponent } from './components/loan-application-header/loan-application-header.component';
import { environment } from '../environments/environment';
import { DevProdInterceptor } from './interceptor/dev-prod.interceptor';
import { API_BASE_URL, APIClient } from '../../../goldstar-share/src/app/api-data/nswag-models';
import { CustomDialogComponent } from '../../../goldstar-share/src/app/components/shared/custom-dialog/custom-dialog.component';
import { GoldenLayoutComponentService } from '../../../goldstar-share/src/app/services/golden-layout-component.service';
import { ShareModule } from '../../../goldstar-share/src/app/share.module';
import { ClientHomeLoggedOutComponent } from './components/client-facing/client-home-logged-out/client-home-logged-out.component';
import { ClientHeaderLoggedOutComponent } from './components/client-facing/client-header-logged-out/client-header-logged-out.component';
import { ApplicationListComponent } from './components/application-list/application-list.component';
import { ClientHeaderLogoOnlyComponent } from './components/client-facing/client-header-logo-only/client-header-logo-only.component';
import { DxSelectBoxModule, DxTextAreaModule, DxFormModule, DxNumberBoxModule, DxCheckBoxModule } from 'devextreme-angular';
import { DevExtremeModule } from 'devextreme-angular';
import { NgxPlaidLinkModule } from 'ngx-plaid-link';
import { StoreModule } from '@ngrx/store';
import { storeReducer } from './store/store.reducer';
import { ApiModule as ApiModuleUpdate } from '../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/api.module';
import { DebitCardApplicationModule } from './modules/debit-card-application/debit-card-application.module';
import { ApplicationRouteGuard } from './guard/application-route-guard';
import { ScreenSizeObserverService } from './services/screen-size-observer.service';
import { SendMessageComponent } from './components/send-message/send-message.component';

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		LoanApplicationHeaderComponent,
		CustomDialogComponent,
		ClientHomeLoggedOutComponent,
		ClientHeaderLoggedOutComponent,
		ClientHeaderLogoOnlyComponent,
		ApplicationListComponent,
  SendMessageComponent,
	],
	imports: [
		CommonModule,
		BrowserModule,
		DevExtremeModule,
		DxSelectBoxModule,
		DxTextAreaModule,
		DxFormModule,
		DxNumberBoxModule,
		DxCheckBoxModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		ShareModule,
		DebitCardApplicationModule,
		NgxPlaidLinkModule,
		HttpClientModule,
		StoreModule.forRoot({ store: storeReducer }),
		ApiModuleUpdate.forRoot({ rootUrl: environment.nodeApiRoot }),
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: DevProdInterceptor, multi: true },
		GoldenLayoutComponentService,
		{ provide: API_BASE_URL, useValue: environment.apiRoot },
		APIClient,
		{ provide: 'environment', useValue: environment },
		ApplicationRouteGuard,
		ScreenSizeObserverService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
