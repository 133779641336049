import { DebitCardApplication, DebitCardApplicationItem, State } from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { Branch } from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { AddressInfo } from '../../../../../../goldstar-share/src/app/components/shared/address-autocomplete/address-autocomplete.component';

export enum Screen {
	Dashboard = 'Dashboard',
	NewCustomer = 'NewCustomer',
}

/**
 * Enum which denotes the various states of a debit card application flow
 */
export enum DebitCardApplicationStage {
	// New Status is Begin verification
	BeginVerification = 'Begin verification',

	// New Status is Phone Number verification
	PhoneNumberVerification = 'Phone Number verification',

	// New Status is Phone number verified
	OtpValidation = 'OtpValidation',

	// New Status is Information -1
	Application2 = 'Application2',

	// New Status is Information -2
	ApplicationSection3 = 'ApplicationSection3',

	// New Status is Information- summary
	ApplicationSummary = 'ApplicationSummary',

	// New Status is Identity verification
	IdentityVerification = 'Identity verification',

	// New Status is Authorization
	ApplicationAuthorization = 'ApplicationAuthorization',

	// New Status is Verified
	ApplicationSubmitted = 'ApplicationSubmitted',
}

export enum DebitCardApplicationStatus {
	IN_PROCESS_BEGIN_VERIFICATION = 'IN_PROCESS_BEGIN_VERIFICATION',
	IN_PROCESS_PHONE_NUMBER_VERIFICATION = 'IN_PROCESS_PHONE_NUMBER_VERIFICATION',
	IN_PROCESS_PHONE_VERIFIED = 'IN_PROCESS_PHONE_VERIFIED',
	IN_PROCESS_ADDRESS = 'IN_PROCESS_ADDRESS',
	IN_PROCESS_DEMOGRAPHICS = 'IN_PROCESS_DEMOGRAPHICS',
	IN_PROCESS_INFORMATION_SUMMARY = 'IN_PROCESS_INFORMATION_SUMMARY',
	IN_PROCESS_IDENTITY_VERIFICATION = 'IN_PROCESS_IDENTITY_VERIFICATION',
	IN_PROCESS_AUTHORIZATION = 'IN_PROCESS_AUTHORIZATION',
	IN_PROCESS_VERIFIED = 'IN_PROCESS_VERIFIED',
	PEN_REVIEW = 'PEN_REVIEW',
}

export interface DebitCardApplicationScreenState {
	activeScreen: DebitCardApplicationStatus;
}

export interface NavigationState {
	activeScreen: Screen;
}

export enum SpinnerState {
	Show = 'Show',
	Hide = 'Hide',
}

export interface ICustomDialogComponentData {
	title: string;
	body: string;
	btnAction: DialogBtnAction;

	confirmBtnLabel?: string;
	cancelBtnLabel?: string;
}

export enum DialogBtnAction {
	Confirm = 'Confirm',
	Cancel = 'Cancel',
}

export const dxUsMonthYearDateFormat = 'MM/dd/yyyy';

export interface CountryInfo {
	countryCode: string;
	countryShortLabel: string;
	countryFullName: string;
}
export enum IdentificationType {
	SSN = 'Social Security Number',
	SIN = 'Social Insurance Number',
	CURP = 'National ID Number',
}
export class UserIdentificationInfo {
	countryShortName?: string | null;
	identificationType?: IdentificationType;
	identificationNumber?: string | null;
}

export interface DebitCardApplicationEntity extends DebitCardApplicationItem {
	userIdentificationInfo?: UserIdentificationInfo | null;
}

export class BranchDistanceCollection {
	[key: number]: UserNearestBranchInfo;
}

export class UserNearestBranchInfo {
	branchInfo!: Branch;
	addressInfo!: AddressInfo;
}

export interface DebitCardApplicationInfo extends DebitCardApplication {
	nearestBranchAddressInfo?: UserNearestBranchInfo | null;
}
