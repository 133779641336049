import { Directive, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';
import { Subscription, fromEvent, throttleTime, debounceTime } from 'rxjs';

@Directive({
	selector: '[fluidHeight]',
})
export class FluidHeightDirective {
	private readonly heightOffset: number = 85;
	@Input('fluidHeight') topOffset!: string | undefined;

	private domElement: HTMLElement;
	private resizeSub: Subscription;

	constructor(private renderer: Renderer2, private elementRef: ElementRef) {
		// get ref HTML element
		this.domElement = this.elementRef.nativeElement as HTMLElement;

		// register on window resize event
		this.resizeSub = fromEvent(window, 'resize')
			.pipe(throttleTime(500), debounceTime(500))
			.subscribe(() => this.setHeight());
	}

	ngAfterViewInit() {
		this.setHeight();
	}

	ngOnDestroy() {
		this.resizeSub.unsubscribe();
	}

	private setHeight() {
		const windowHeight = window?.innerHeight - this.heightOffset;
		this.renderer.setStyle(this.domElement, 'height', `${windowHeight}px`);
	}
}
