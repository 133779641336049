import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DebitCardApplicationDashboardComponent } from './components/debit-card-application-dashboard/debit-card-application-dashboard.component';
import { RouterModule, Routes } from '@angular/router';
import { ShareModule } from '../../../../../goldstar-share/src/app/share.module';
import { DebitCardApplicationService } from './services/debit-card-application.service';
import { NewCustomerAcknowledgementComponent } from './components/new-customer-acknowledgement/new-customer-acknowledgement.component';
import { DxModule } from '../../../../../goldstar-share/src/app/modules/dx/dx.module';
import { MasterDataService } from './services/master-data.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { DebitCardApplicationEditComponent } from './components/debit-card-application-edit/debit-card-application-edit.component';
import { DebitCardLoginComponent } from './components/debit-card-login/debit-card-login.component';
import { DebitCardApplication2Component } from './components/debit-card-application2/debit-card-application2.component';
import { DebitCardApplication3Component } from './components/debit-card-application3/debit-card-application3.component';
import { DebitCardApplicationSummaryComponent } from './components/debit-card-application-summary/debit-card-application-summary.component';
import { S3FileService } from '../../../../../goldstar-share/src/app/services/s3file-service';
import { ApiService } from '../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services';
import { PhoneVerificationComponent } from './components/phone-verification/phone-verification.component';
import { OtpValidationComponent } from './components/otp-validation/otp-validation.component';
import { DebitCardApplicationAuthorizationComponent } from './components/debit-card-application-authorization/debit-card-application-authorization.component';
import { DebitCardSubmittedComponent } from './components/debit-card-submitted/debit-card-submitted.component';
import { BaseApplicationComponent } from './components/base-application/base-application.component';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { DebitCardIdentityVerificationFailedComponent } from './components/debit-card-identity-verification-failed/debit-card-identity-verification-failed.component';
import { BranchLocatorService } from './services/branch-locator.service';
import { FluidHeightDirective } from './directives/fluid-height.directive';
import { ControlHeightDirective } from './directives/control-height.directive';
import { MaterialModule } from '../../../../../goldstar-share/src/app/modules/material/material.module';

const routes: Routes = [
	{
		path: 'dashboard',
		component: DebitCardApplicationDashboardComponent,
	},
	{
		path: 'login',
		component: DebitCardLoginComponent,
	},
	{
		path: 'login/:id',
		component: DebitCardLoginComponent,
	},
	{
		path: 'phoneVerification',
		component: PhoneVerificationComponent,
	},
	{
		path: 'phoneVerification/:id',
		component: PhoneVerificationComponent,
	},
	{
		path: 'otpValidation',
		component: OtpValidationComponent,
	},
	{
		path: 'otpValidation/:id',
		component: OtpValidationComponent,
	},
	{
		path: 'application2',
		component: DebitCardApplication2Component,
	},
	{
		path: 'application2/:id',
		component: DebitCardApplication2Component,
	},
	{
		path: 'application3',
		component: DebitCardApplication3Component,
	},
	{
		path: 'application3/:id',
		component: DebitCardApplication3Component,
	},
	{
		path: 'applicationSummary',
		component: DebitCardApplicationSummaryComponent,
	},
	{
		path: 'applicationSummary/:id',
		component: DebitCardApplicationSummaryComponent,
	},
	{
		path: 'identityVerificationFailed',
		component: DebitCardIdentityVerificationFailedComponent,
	},
	{
		path: 'identityVerificationFailed/:id',
		component: DebitCardIdentityVerificationFailedComponent,
	},
	{
		path: 'debitCardApplicationEdit',
		component: DebitCardApplicationEditComponent,
	},
	{
		path: 'authorization',
		component: DebitCardApplicationAuthorizationComponent,
	},
	{
		path: 'authorization/:id',
		component: DebitCardApplicationAuthorizationComponent,
	},
	{
		path: 'submitted',
		component: DebitCardSubmittedComponent,
	},
	{
		path: 'submitted/:id',
		component: DebitCardSubmittedComponent,
	},
];

@NgModule({
	providers: [DebitCardApplicationService, DatePipe, { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }, S3FileService, ApiService, BranchLocatorService],
	declarations: [
		DebitCardApplicationDashboardComponent,
		NewCustomerAcknowledgementComponent,
		DebitCardApplicationEditComponent,
		DebitCardLoginComponent,
		DebitCardApplication2Component,
		DebitCardApplication3Component,
		DebitCardApplicationSummaryComponent,
		PhoneVerificationComponent,
		OtpValidationComponent,
		DebitCardApplicationAuthorizationComponent,
		DebitCardSubmittedComponent,
		BaseApplicationComponent,
		DebitCardIdentityVerificationFailedComponent,
		FluidHeightDirective,
		ControlHeightDirective,
	],
	imports: [CommonModule, RouterModule.forChild(routes), ShareModule, DxModule, NgxSpinnerModule, NgxOtpInputModule, MaterialModule],
})
export class DebitCardApplicationModule {
	constructor(private masterDataService: MasterDataService) {}
}
