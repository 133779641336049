import { Component, OnInit } from '@angular/core';
import { ApplicationTestService } from '../../services/applicationTestService';
import { ApiService } from '../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services/api.service';

@Component({
	selector: 'app-application-list',
	templateUrl: './application-list.component.html',
	styleUrls: ['./application-list.component.scss'],
})
export class ApplicationListComponent implements OnInit {
	constructor(private testService: ApplicationTestService, private apiV2: ApiService) {}

	ngOnInit(): void {}
}
