import { Injectable } from '@angular/core';
import { ApplicationStatus, CitizenshipType, EmploymentStatus, State } from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { ApiService } from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services';
import { EntityGetRequest } from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { BehaviorSubject, forkJoin, lastValueFrom } from 'rxjs';
import { Result, Token } from '../../../../../../goldstar-share/src/app/models/models';
import { ResultHelper } from '../common/result-extension';
import { CountryInfo } from '../models/model';

@Injectable({
	providedIn: 'root',
})
export class MasterDataService {
	public clientToken!: Token | undefined;
	private readonly defaultCitizenshipTypes: CitizenshipType[] = [];
	private readonly defaultEmploymentStatus: EmploymentStatus[] = [];
	private readonly defaultStates: State[] = [];
	private readonly defaultApplicationStatus: ApplicationStatus[] = [];

	// This cannot be fetched from the the API, because API calls are successful post user verifications
	// where country code is required before user verification
	private readonly countryInfos: CountryInfo[] = [
		{
			countryCode: '+1',
			countryFullName: 'USA',
			countryShortLabel: 'US',
		},
		{
			countryCode: '+1',
			countryFullName: 'CA',
			countryShortLabel: 'CA',
		},
		{
			countryCode: '+52',
			countryFullName: 'MX',
			countryShortLabel: 'MX',
		},
		{
			countryCode: '+91',
			countryFullName: 'IN',
			countryShortLabel: 'IN',
		},
	];

	private allCitizenshipTypes: BehaviorSubject<CitizenshipType[]> = new BehaviorSubject(this.defaultCitizenshipTypes);
	private allEmploymentStatus: BehaviorSubject<EmploymentStatus[]> = new BehaviorSubject(this.defaultEmploymentStatus);
	private allStates: BehaviorSubject<State[]> = new BehaviorSubject(this.defaultStates);
	private allApplicationStatus: BehaviorSubject<ApplicationStatus[]> = new BehaviorSubject(this.defaultApplicationStatus);
	private allCountryInfos: BehaviorSubject<CountryInfo[]> = new BehaviorSubject(this.countryInfos);

	constructor(private apiV2: ApiService) {}

	async initialize(): Promise<Result<string>> {
		// Initialize master data for debit card application
		const fetchAllMasterDataRequest: EntityGetRequest = {
			searchFilter: [{ searchOption: 'ALL' }],
			pageSize: 1000,
		};

		const allMasterDataRequests: any[] = [
			lastValueFrom(this.apiV2.citizenshipTypeList({ body: fetchAllMasterDataRequest })),
			lastValueFrom(this.apiV2.employeeStatusList({ body: fetchAllMasterDataRequest })),
			lastValueFrom(this.apiV2.stateList({ body: fetchAllMasterDataRequest })),
			lastValueFrom(this.apiV2.applicationStatusList({ body: fetchAllMasterDataRequest })),
		];

		return await Promise.all(allMasterDataRequests)
			.then((response) => {
				if (response && response.length > 2) {
					if (response[0] && response[0].isSuccess && response[0].data && response[0].data.items) {
						const citizenTypeItems = response[0].data.items as CitizenshipType[];
						this.allCitizenshipTypes.next(citizenTypeItems);
					}
					if (response[1] && response[1].isSuccess && response[1].data && response[1].data.items) {
						const employmentStatus = response[1].data.items as EmploymentStatus[];
						this.allEmploymentStatus.next(employmentStatus);
					}
					if (response[2] && response[2].isSuccess && response[2].data && response[2].data.items) {
						const states = response[2].data.items as State[];
						this.allStates.next(states);
					}
					if (response[3] && response[3].isSuccess && response[3].data && response[3].data.items) {
						const applicationStatus = response[3].data.items as ApplicationStatus[];
						this.allApplicationStatus.next(applicationStatus);
					}
					return ResultHelper.successResponse('Successfully data loaded');
				} else {
					throw Error('Failed to load data');
				}
			})
			.catch((error) => {
				console.log('Failed to load data');
				return ResultHelper.failedResponse(error);
			});
	}

	async fetchAllCitizenshipTypes(): Promise<CitizenshipType[]> {
		if (this.allCitizenshipTypes.getValue().length <= 0) {
			const response = await this.initialize();
			if (!response.isSuccess) {
				throw Error('Failed to load data');
			}
		}
		return this.allCitizenshipTypes.getValue();
	}

	async fetchAllEmploymentStatus(): Promise<EmploymentStatus[]> {
		if (this.allEmploymentStatus.getValue().length <= 0) {
			const response = await this.initialize();
			if (!response.isSuccess) {
				throw Error('Failed to load data');
			}
		}
		return this.allEmploymentStatus.getValue();
	}

	async fetchAllState(): Promise<State[]> {
		if (this.allStates.getValue().length <= 0) {
			const response = await this.initialize();
			if (!response.isSuccess) {
				throw Error('Failed to load data');
			}
		}
		return this.allStates.getValue();
	}

	async fetchAllStatus(): Promise<ApplicationStatus[]> {
		if (this.allApplicationStatus.getValue().length <= 0) {
			const response = await this.initialize();
			if (!response.isSuccess) {
				throw Error('Failed to load data');
			}
		}
		return this.allApplicationStatus.getValue();
	}

	async fetchAllCountryInfo(): Promise<CountryInfo[]> {
		return this.allCountryInfos.getValue();
	}

	async resolveCountryInfoFromCountryFullName(countryFullName: string): Promise<Result<CountryInfo>> {
		const matchingInfo = (await this.fetchAllCountryInfo()).find((x) => x.countryFullName === countryFullName);
		if (matchingInfo) {
			return ResultHelper.successResponse(matchingInfo);
		}
		return ResultHelper.failedResponse('Could not find matching country info');
	}

	async resolveCountryInfoFromCountryCode(countryCode: string): Promise<Result<CountryInfo>> {
		const matchingInfo = (await this.fetchAllCountryInfo()).find((x) => x.countryCode === countryCode);
		if (matchingInfo) {
			return ResultHelper.successResponse(matchingInfo);
		}
		return ResultHelper.failedResponse('Could not find matching country info');
	}
}
