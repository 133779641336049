import { Injectable } from '@angular/core';
import { error } from 'console';
import { Result } from '../../../../../../goldstar-share/src/app/models/models';
import {
	DebitCardApplication,
	DebitCardApplicationItem,
	DebitCardApplicationRequest,
	GenerateOtpRequest,
	OldDebitCardApplicationRequest,
	PlaidIdentityInfoRequest,
	SendMessageRequest,
} from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { ApiService } from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services';
import { lastValueFrom } from 'rxjs';
import { ResultHelper } from '../common/result-extension';

@Injectable({
	providedIn: 'root',
})
export class DebitCardApplicationService {
	constructor(private apiV2: ApiService) {}

	async fetchDebitCardApplicationList(request: DebitCardApplicationRequest): Promise<Result<DebitCardApplication[]>> {
		const response = await lastValueFrom(this.apiV2.debitCardApplicationList({ body: request })).then((response) => {
			if (!response.isSuccess) {
				return ResultHelper.failedResponse<DebitCardApplication[]>('Failed to fetch data');
			}
			const dataItems = response.data && response.data.items ? response.data.items : [];
			return ResultHelper.successResponse(dataItems);
		});
		return response;
	}

	async addDebitCardApplication(request: DebitCardApplicationItem): Promise<string> {
		return await lastValueFrom(this.apiV2.debitCardApplicationAdd({ body: request })).then((response) => {
			if (response.isSuccess) {
				return response.data ?? '';
			}
			throw response.message;
		});
	}

	async overrideAndAddDebitCardApplication(request: OldDebitCardApplicationRequest): Promise<DebitCardApplication[]> {
		return await lastValueFrom(this.apiV2.overrideAndAddDebitCardApplication({ body: request })).then((response) => {
			if (response.isSuccess) {
				return response.data?.items ?? [];
			}
			throw response.message;
		});
	}

	async persistUserIdentityInfo(request: PlaidIdentityInfoRequest): Promise<string> {
		return await lastValueFrom(this.apiV2.identityVerificationInfoAdd({ body: request }))
			.then(async (response) => {
				if (response.isSuccess && response.data) {
					return response.data ?? '';
				}
				throw response.message;
			})
			.catch((error) => {
				throw error;
			});
	}

	async sendVerificationCode(request: GenerateOtpRequest): Promise<Result<string>> {
		const value = await lastValueFrom(this.apiV2.sendVerificationCode({ body: request }))
			.then((response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse<string>(response.data ?? '');
				}
				throw Error('Could not send verification code');
			})
			.catch((error) => {
				return ResultHelper.failedResponse<string>(error);
			});

		return value;
	}

	async sendMessage(request: SendMessageRequest): Promise<Result<string>> {
		const value = await lastValueFrom(this.apiV2.sendMessage({ body: request }))
			.then((response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse<string>(response.data ?? '');
				}
				throw Error('Could send message');
			})
			.catch((error) => {
				return ResultHelper.failedResponse<string>(error);
			});

		return value;
	}
}
