import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-custom-dialog',
	templateUrl: './custom-dialog.component.html',
	styleUrls: ['./custom-dialog.component.scss'],
})
export class CustomDialogComponent implements OnInit {
	constructor(public dialogRef: MatDialogRef<CustomDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ICustomDialogComponentData) {}

	ngOnInit() {}

	onYesClick(): void {
		this.data.btnAction = DialogBtnAction.Confirm;
		this.dialogRef.close(this.data);
	}

	onNoClick(): void {
		this.data.btnAction = DialogBtnAction.Cancel;
		this.dialogRef.close(this.data);
	}
}

export interface ICustomDialogComponentData {
	title: string;
	body: string;
	btnAction: DialogBtnAction;

	confirmBtnLabel?: string;
	cancelBtnLabel?: string;
}

export enum DialogBtnAction {
	Confirm = 'Confirm',
	Cancel = 'Cancel',
}
