<ngx-spinner *ngIf="showSpinner" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
	<p style="color: white">Loading...</p>
</ngx-spinner>
<div [fluidHeight] class="row col-lg-12 col-md-12 application-container">
	<div class="header application-content-container">
		<div>
			<div class="row col-lg-12 col-md-12 custom-row">
				<div class="header-image-container">
					<img class="header-image" src="../../../assets/images/Trb_Bank_Logo_with_caption.png" />
				</div>
			</div>
			<div class="body-container">
				<div>
					<h1 class="verify-phone-label">Verify your Phone Number</h1>
				</div>
				<div>
					<ul class="disclaimer-container">
						<li class="line-height-medium">The security of your information is our top priority.</li>
						<li class="line-height-medium">To help reduce the risk of fraud, we will now send you a one-time verification code.</li>
					</ul>
				</div>
				<div class="send-code-btn-container">
					<div>
						<button class="send-code-btn" style="border-radius: 10px" mat-raised-button color="primary" (click)="navigateToOtpValidation()">Send me my Code</button>
					</div>
					<!-- <div>
						<button class="send-code-btn" style="border-radius: 10px" mat-raised-button color="primary" (click)="sendMessage()">Send me message</button>
					</div> -->
				</div>
				<div class="footer-label-container">
					<ul class="footer-label-list">
						<li class="line-height-medium footer-label" style="margin-left: -20px">Message and data rates may apply.</li>
					</ul>
				</div>
			</div>
		</div>
		<div *ngIf="fullFooterLayout" class="row footer" style="padding-top: 10px; padding-right: 10px; margin-bottom: 10px; width: 100%">
			<div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
				<button class="back-btn" mat-button style="border-radius: 10px" (click)="navigateBackToLogin()">Back</button>
				<img src="../../../assets/images/logo_watermark.png" height="30vh" />
				<div style="display: flex; flex-direction: row; align-items: center">
					<img src="../../../assets/images/lock_not_filled.svg" height="10vh" />
					<span style="font-size: 10px; color: lightgray"> Your security matters to us</span>
				</div>
			</div>
		</div>
		<div *ngIf="trimmedFooter" class="row footer" style="margin-bottom: 10px">
			<div style="display: flex; flex-direction: row; justify-content: center">
				<button class="back-btn" mat-button style="border-radius: 10px" (click)="navigateBackToLogin()">Back</button>
			</div>
		</div>
	</div>
</div>
