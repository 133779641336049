<div class="col-lg-12 col-md-12 application-container">
	<div class="app-detail-container">
		<div class="row col-lg-12 col-md-12" style="display: flex; height: inherit">
			<div class="col-lg-6 col-md-6 background-image-cover" style="background-image: url('./../../../../assets/images/bank-image.png')">
				<div class="header-image-container">
					<img class="header-image" src="../../../assets/images/Trb_Bank_Logo_with_caption.png" />
				</div>
				<div class="row" style="height: inherit; margin-top: 10%; text-align: center">
					<ul style="display: flex; text-align: center; flex-direction: column; align-items: center">
						<li style="padding-bottom: 20px">
							<div class="dashboard-header-font-size" style="margin-right: 45px">Let's</div>
						</li>
						<li style="padding-bottom: 20px">
							<div class="dashboard-header-font-size default-margin-right">Get</div>
						</li>
						<li><div class="dashboard-header-font-size padding-default">Started</div></li>
					</ul>
				</div>
				<div style="padding-left: 10px; padding-bottom: 10px">
					<div style="display: flex; flex-direction: row; justify-content: space-between">
						<img src="../../../assets/images/logo_watermark.png" height="30vh" />
						<div style="display: flex; flex-direction: row; align-items: center">
							<img src="../../../assets/images/lock_not_filled.svg" height="10vh" />
							<span style="font-size: 10px; color: grey"> Your security matters to us</span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6 col-md-6 dashboard-content-container">
				<div class="row header dashboard-default-padding">
					<ul style="display: flex; text-align: center; flex-direction: column; align-items: center">
						<li><div style="font-size: 27px; color: #8b7054">Welcome to</div></li>
						<li><div style="font-size: 27px; color: #8b7054; font-weight: 500; padding-top: 15px">Texas Regional Bank</div></li>
					</ul>
				</div>
				<div class="row body" style="margin-bottom: 70px; padding-left: 40px">
					<ul>
						<li style="color: #8b7054">
							<div style="font-size: 25px; padding-bottom: 20px; color: #002b5c">What you will need:</div>
							<ul>
								<div style="display: flex; align-items: center">
									<img src="../../../assets/images/green_solid_tick.svg" height="30vh" />
									<li style="font-size: 18px; color: #002b5c; padding-left: 10px">You must be 18 years old and a U.S Citizen or Resident Alien</li>
								</div>
								<div style="display: flex; align-items: center; padding-top: 15px">
									<img src="../../../assets/images/green_solid_tick.svg" height="30vh" />
									<li style="color: #002b5c; padding-left: 10px">
										<div style="font-size: 18px">Government-issued Photo ID</div>
										<ul style="margin-left: -30px">
											<li style="color: grey">Valid U.S. driver's license, state-issued ID or Passport</li>
										</ul>
									</li>
								</div>
								<div style="display: flex; align-items: center; padding-top: 15px">
									<img src="../../../assets/images/green_solid_tick.svg" height="30vh" />
									<li style="color: #002b5c; padding-left: 10px">
										<div style="font-size: 18px">Personal Information</div>
										<ul style="margin-left: -30px">
											<li style="color: grey">Social Security Number, Date of Birth, U.S Residential Address</li>
										</ul>
									</li>
								</div>
								<div style="display: flex; align-items: center; padding-top: 15px">
									<img src="../../../assets/images/green_solid_tick.svg" height="30vh" />
									<li style="color: #002b5c; padding-left: 10px">
										<div style="font-size: 18px">Funds</div>
										<ul style="margin-left: -30px">
											<li style="word-break: break-word; width: 80%; color: grey">
												We require a minimum deposit to open your new account. You can fund this new account with either a debit card, a checking or savings account
											</li>
										</ul>
									</li>
								</div>
							</ul>
						</li>
					</ul>
				</div>
				<div class="row footer" style="padding-bottom: 10px">
					<div style="display: flex; flex-direction: row; justify-content: space-between; padding-right: 40px; padding-left: 40px">
						<button mat-button style="border: 2px solid; border-radius: 10px; background: transparent; font-size: 15px; width: 100px" (click)="navigateToDashboard()">Back</button>
						<button mat-button style="border: 2px solid; border-radius: 10px; background: transparent; font-size: 15px; width: 100px" (click)="navigateToNewApplication()">Continue</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
