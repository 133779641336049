import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { DebitCardApplicationDataStore } from '../../services/debit-card-application-data.store';
import { TokenProviderService } from '../../services/token-provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandlingService } from '../../../../../../../goldstar-share/src/app/services/error-handling.service';
import { GenerateOtpRequest, SendMessageRequest } from '../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { DebitCardApplicationStatus, SpinnerState } from '../../models/model';
import { NgxSpinnerService } from 'ngx-spinner';
import { DebitCardApplicationService } from '../../services/debit-card-application.service';
import { UntypedFormBuilder } from '@angular/forms';
import { BaseApplicationComponent } from '../base-application/base-application.component';
import { MasterDataService } from '../../services/master-data.service';

@Component({
	selector: 'app-phone-verification',
	templateUrl: './phone-verification.component.html',
	styleUrls: ['./phone-verification.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class PhoneVerificationComponent extends BaseApplicationComponent implements OnInit {
	serviceInstanceId!: string;
	// This is for full footer (all 3 columns)
	fullFooterLayout: boolean = true;

	trimmedFooter: boolean = false;

	@HostListener('window:load', ['$event'])
	onLoad() {
		if (window.innerWidth <= 468) {
			this.fullFooterLayout = false;
			this.trimmedFooter = true;
		} else {
			this.fullFooterLayout = true;
			this.trimmedFooter = false;
		}
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize(event: any) {
		if (window.innerWidth <= 468) {
			this.fullFooterLayout = false;
			this.trimmedFooter = true;
		} else {
			this.fullFooterLayout = true;
			this.trimmedFooter = false;
		}
	}

	constructor(
		formBuilder: UntypedFormBuilder,
		router: Router,
		activatedRoute: ActivatedRoute,
		store: DebitCardApplicationDataStore,
		tokenProvider: TokenProviderService,
		errorHandlingService: ErrorHandlingService,
		spinnerService: NgxSpinnerService,
		debitCardApplicationService: DebitCardApplicationService,
		masterDataService: MasterDataService
	) {
		super(formBuilder, errorHandlingService, spinnerService, store, router, activatedRoute, debitCardApplicationService, masterDataService);
	}

	async ngOnInit(): Promise<void> {}

	async navigateToOtpValidation() {
		this.toggleSpinner(SpinnerState.Show);
		const otpVerificationRequest: GenerateOtpRequest = {
			countryCode: this.selectedDebitCardApplication.countryCode,
			phoneNumber: this.selectedDebitCardApplication.primaryPhone,
			verificationMode: 'sms',
		};
		this.debitCardApplicationService
			.sendVerificationCode(otpVerificationRequest)
			.then(async (response) => {
				if (response.isSuccess) {
					// await this.store.updateStatus(DebitCardApplicationStatus.IN_PROCESS_PHONE_VERIFIED);
					this.toggleSpinner(SpinnerState.Hide);
					this.serviceInstanceId = response.data ?? '';
					this.errorHandlingService.showSuccessMessage('Successfully send verification code');
					this.routeSelector('debitcardppplication/otpValidation');
				} else {
					this.toggleSpinner(SpinnerState.Hide);
					this.errorHandlingService.showErrorMessage('Error sending verification code, please verify the number and try again');
				}
			})
			.catch((error) => {
				this.toggleSpinner(SpinnerState.Hide);
				this.errorHandlingService.showErrorMessage('Failed to send verification code');
			});
	}

	async sendMessage() {
		this.toggleSpinner(SpinnerState.Show);
		const otpVerificationRequest: SendMessageRequest = {
			message: 'Hello this is dummy message',
			receiverCountryCode: this.selectedDebitCardApplication.countryCode ?? '+91',
			receiverPhoneNumber: this.selectedDebitCardApplication.primaryPhone ?? '',
		};
		this.debitCardApplicationService
			.sendMessage(otpVerificationRequest)
			.then(async (response) => {
				if (response.isSuccess) {
					this.toggleSpinner(SpinnerState.Hide);
					this.serviceInstanceId = response.data ?? '';
					this.errorHandlingService.showSuccessMessage('Successfully send message');
				} else {
					this.toggleSpinner(SpinnerState.Hide);
					this.errorHandlingService.showErrorMessage('Error sending message, please verify the number and try again');
				}
			})
			.catch((error) => {
				this.toggleSpinner(SpinnerState.Hide);
				this.errorHandlingService.showErrorMessage('Failed to send message');
			});
	}

	navigateBackToLogin() {
		this.routeSelector('debitcardppplication/login');
	}
}
