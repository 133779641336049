import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../goldstar-client/src/environments/environment';
import { Result } from '../../../../../../goldstar-share/src/app/models/models';
import { BehaviorSubject, Observable, lastValueFrom, of } from 'rxjs';
import { ITokenServiceProvider } from '../models/interfaces';
import { ResultHelper } from '../common/result-extension';
import { ApiService } from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services';
import { Token } from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models/token';
@Injectable({
	providedIn: 'root',
})

/**
 * It calls an API to generate a token by passing in a UserInfo object.
 * Note: This is not the best place of this service, more suitable will be app module may be?
 */
export class TokenProviderService implements ITokenServiceProvider {
	private readonly defaultTokenValue: Token = {
		value: 'Default',
		expirationTime: '',
	};

	private token$Observable: BehaviorSubject<Token> = new BehaviorSubject(this.defaultTokenValue);

	constructor(private http: HttpClient, private apiV2: ApiService) {}

	/**
	 * fetches the token from server and caches it as part of Token variable
	 * @param Token result
	 * @returns
	 */
	async fetchToken(tokenInfo: any): Promise<Result<Token>> {
		try {
			if (!environment.extIdentityProviderRoot) {
				throw Error('Identity Provider URL could not be fetched');
			}
			const fetchTokenResponse = await lastValueFrom(this.apiV2.acquireToken({ body: tokenInfo }));
			if (fetchTokenResponse.isSuccess && fetchTokenResponse.data?.items && fetchTokenResponse.data.items.length > 0) {
				const token = fetchTokenResponse.data?.items[0];
				this.token$Observable.next(token);
				return ResultHelper.successResponse(token);
			}
			throw new Error('Otp validation failed');
		} catch (error: any) {
			return ResultHelper.failedResponse(error);
		}
	}

	/**
	 * returns the cached token if valid, if invalid return failed response
	 * @returns: Token result
	 */
	acquireToken(): Observable<Result<Token>> {
		try {
			const token = this.token$Observable.getValue();
			const result = ResultHelper.successResponse(token);
			return of(result);
		} catch (error: any) {
			const failedResult = ResultHelper.failedResponse<Token>(error);
			return of(failedResult);
		}
	}
}
