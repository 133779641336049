<ngx-spinner *ngIf="showSpinner" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
	<p style="color: white">Loading...</p>
</ngx-spinner>
<div [fluidHeight] class="row col-lg-12 col-md-12 application-container">
	<div class="header" style="height: inherit; display: flex; justify-content: space-between">
		<div class="row col-lg-12 col-md-12 custom-row" style="padding-top: 50px">
			<div class="row col-lg-12 col-md-12">
				<div class="header-image-container">
					<img class="header-image" src="../../../assets/images/Trb_Bank_Logo_with_caption.png" />
				</div>
			</div>
			<div style="padding: 40px; overflow-y: auto">
				<section>
					<h2>Authorization</h2>
					<section>
						<p style="font-size: 12px; color: grey; font-weight: 500">
							Each of the Applicant(s) identified hereby agrees and certifies that all the information on this Application is true and correct to the best of his/her knowledge and
							authorizes the Bank to investigate and verify the employment and credit history of each of the undersigned Applicant(s), and to obtain such credit reports as the Bank deems
							necessary. Each of the undersigned also authorizes the release of any personal information by any federal , state or local agencies, as well as by other Banks, creditors or
							employers requested by the Bank.
						</p>
					</section>
				</section>
				<section>
					<hr />
				</section>
				<section>
					<div class="btn-container">
						<dx-check-box class="approve-content-wrapper-checkbox" [(value)]="acceptedTermsAndCondition" [iconSize]="15" (onValueChanged)="handleValueChange($event)"> </dx-check-box>
						<span class="approve-content-wrapper" style="font-size: 12px">I agree to the above terms</span>
					</div>
					<div *ngIf="showTermsAndConditionValidation" style="padding-top: 25px">
						<span style="color: red; background-color: #ffebee; border-radius: 5px; font-weight: 500; padding: 10px; border: 2px solid; font-size: 12px"
							>Authorization needs to be accepted</span
						>
					</div>
				</section>
			</div>
			<div class="row footer" style="padding-right: 40px; padding-top: 10px">
				<div *ngIf="fullFooterLayout" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
					<button
						mat-button
						style="border: 2px solid; border-radius: 10px; background: transparent; font-size: 15px; min-width: 100px; margin-left: 30px"
						(click)="navigateBackToApplication3()"
					>
						Previous
					</button>
					<img src="../../../assets/images/logo_watermark.png" height="30vh" />
					<button
						mat-button
						style="border: 2px solid; border-radius: 10px; background: transparent; font-size: 15px; margin-left: 15px; min-width: 100px"
						(click)="navigateToApplicationSubmitted()"
					>
						Submit Application
					</button>
				</div>
				<div *ngIf="trimmedFooter" style="display: flex; flex-direction: row; justify-content: center; align-items: center">
					<button
						mat-button
						style="border: 2px solid; border-radius: 10px; background: transparent; font-size: 15px; margin-left: 15px; min-width: 100px"
						(click)="navigateToApplicationSubmitted()"
					>
						Submit Application
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
