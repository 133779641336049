import { BehaviorSubject } from 'rxjs';
import { DebitCardApplicationService } from './debit-card-application.service';
import { Injectable } from '@angular/core';
import { DebitCardApplicationInfo, DebitCardApplicationStatus, UserNearestBranchInfo } from '../models/model';
import { Result } from '../../../../../../goldstar-share/src/app/models/models';
import { DebitCardApplicationRequest } from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { ResultHelper } from '../common/result-extension';
import { MasterDataService } from './master-data.service';
import { CollectionHelper } from '../../../../../../goldstar-share/src/app/services/collection.helper';

@Injectable({
	providedIn: 'root',
})
export class DebitCardApplicationDataStore {
	private readonly defaultDebitCardApplication: DebitCardApplicationInfo = {};
	private debitCardApplicationModel: BehaviorSubject<DebitCardApplicationInfo> = new BehaviorSubject(this.defaultDebitCardApplication);
	constructor(private debitCardApplicationService: DebitCardApplicationService, private masterDataService: MasterDataService) {}

	async replaceValue(debitCardApplication: DebitCardApplicationInfo) {
		this.debitCardApplicationModel.next(debitCardApplication);
	}

	async patchValues(debitCardApplication: DebitCardApplicationInfo) {
		const applicationItem = await this.getValue();
		const keyValuePairs = Object.entries(debitCardApplication);
		keyValuePairs.forEach(([key, value]) => {
			applicationItem[key as keyof DebitCardApplicationInfo] = value;
		});
		this.debitCardApplicationModel.next(applicationItem);
	}

	async updateStatus(status: DebitCardApplicationStatus) {
		const debitCardApplication = await this.getValue();
		const allApplicationStatus = await this.masterDataService.fetchAllStatus();
		const matchingStatus = allApplicationStatus.filter((x) => x.applicationStatusSystemCode === status);
		if (matchingStatus) {
			debitCardApplication.applicationStatusGUID = matchingStatus[0].applicationStatusGUID;
		}
		this.debitCardApplicationModel.next(debitCardApplication);
	}

	async resetApplication() {
		const debitCardApplication = await this.getValue();
		debitCardApplication.debitCardApplicationGUID = null;
		debitCardApplication.addressLine = null;
		debitCardApplication.addressLine2 = null;
		debitCardApplication.mailingAddressSameAsPhyAddrYN = false;
		debitCardApplication.birthCity = null;
		debitCardApplication.birthCountry = null;
		debitCardApplication.birthState = null;
		debitCardApplication.citizenTypeGUID = null;
		debitCardApplication.city = null;
		debitCardApplication.stateGUID = null;
		debitCardApplication.zipCode = null;
		debitCardApplication.ssn = null;
		debitCardApplication.mailingAddress = null;
		debitCardApplication.mailingAddressLine2 = null;
		debitCardApplication.mailingCity = null;
		debitCardApplication.mailingStateGUID = null;
		debitCardApplication.mailingZip = null;
		debitCardApplication.middleName = null;
		debitCardApplication.foreignDelegateYN = null;
		debitCardApplication.freeDebitCardYN = null;
		debitCardApplication.overdraftProjectionYN = null;
		debitCardApplication.overdraftProtectionDocViewedYN = false;
		debitCardApplication.combinedDisclosureYN = null;
		debitCardApplication.combinedDisclosureDocViewedYN = false;
		debitCardApplication.disclosureAgreementDocViewedYN = false;
		debitCardApplication.disclosureAgreementYN = null;
		debitCardApplication.regulationYN = null;
		debitCardApplication.regulationDocViewedYN = false;
		debitCardApplication.identityVerifiedYN = false;
		debitCardApplication.userIdentityInfoGUID = null;
		debitCardApplication.dob = null;
		debitCardApplication.occupation = null;
		debitCardApplication.employer = null;
		this.debitCardApplicationModel.next(debitCardApplication);
	}

	async getValue(): Promise<DebitCardApplicationInfo> {
		const data = this.debitCardApplicationModel.getValue();
		return data;
	}

	async push(): Promise<string> {
		const debitCardApplication = await this.getValue();
		return await this.debitCardApplicationService
			.addDebitCardApplication(debitCardApplication)
			.then((response) => {
				return response;
			})
			.catch((error) => {
				throw error;
			});
	}

	/**
	 * Retrieves a debit card application from database based on the application request
	 */
	async fetchValue(applicationFetch: DebitCardApplicationRequest): Promise<Result<DebitCardApplicationInfo[]>> {
		return await this.debitCardApplicationService
			.fetchDebitCardApplicationList(applicationFetch)
			.then(async (response) => {
				if (response.isSuccess && response.data) {
					const matchingDebitCardApplication: DebitCardApplicationInfo = response.data[0];
					if (matchingDebitCardApplication) {
						if (matchingDebitCardApplication.nearestBranchGUID) {
							const states = await this.masterDataService.fetchAllState();
							const matchingBranch = CollectionHelper.selectMany(states, (x) => x.branches ?? []).find((x) => x.branchGUID === matchingDebitCardApplication.nearestBranchGUID);
							if (matchingBranch) {
								const userNearestBranchInfo: UserNearestBranchInfo = {
									branchInfo: matchingBranch,
									addressInfo: {
										address: matchingBranch.branchAddress ?? '',
										city: matchingBranch.branchCity ?? '',
										latitude: matchingBranch.latitude ?? '',
										longitude: matchingBranch.longitude ?? '',
										state: matchingBranch.stateCode ?? '',
										zip: matchingBranch.zipCode ?? '',
										branchWebsiteUrl: matchingBranch.webSiteUrl ?? '',
									},
								};
								matchingDebitCardApplication.nearestBranchAddressInfo = userNearestBranchInfo;
							}
						}
						await this.replaceValue(matchingDebitCardApplication);
					}
				}
				return response;
			})
			.catch((error) => {
				console.log('Error fetching data ----', error);
				return ResultHelper.failedResponse(error);
			});
	}
}
