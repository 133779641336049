import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DebitCardApplicationDataStore } from '../../services/debit-card-application-data.store';
import { ErrorHandlingService } from '../../../../../../../goldstar-share/src/app/services/error-handling.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MasterDataService } from '../../services/master-data.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { State } from '../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { DxValidationGroupComponent } from 'devextreme-angular';
import { CountryInfo, DebitCardApplicationInfo, DebitCardApplicationStatus, SpinnerState } from '../../models/model';
import { AddressAutocompleteComponent, AddressInfo } from '../../../../../../../goldstar-share/src/app/components/shared/address-autocomplete/address-autocomplete.component';
import { GooglePlaceAutocompleteOptions } from '../../../../../../../goldstar-share/src/app/models/models';
import { BaseApplicationComponent } from '../base-application/base-application.component';
import { TokenProviderService } from '../../services/token-provider.service';
import { DebitCardApplicationService } from '../../services/debit-card-application.service';
import { BranchLocatorService } from '../../services/branch-locator.service';

@Component({
	selector: 'app-debit-card-application2',
	templateUrl: './debit-card-application2.component.html',
	styleUrls: ['./debit-card-application2.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DebitCardApplication2Component extends BaseApplicationComponent implements OnInit {
	userPrimaryAddressInfo!: AddressInfo;
	application2Form: UntypedFormGroup = this.formBuilder.group({
		addressLine1: [null, Validators.required],
		addressLine2: [null, Validators.required],
		city: [null, Validators.required],
		stateGUID: [null, Validators.required],
		zipCode: [null, Validators.required],
		mailingAddressLine1: [null],
		mailingAddressLine2: [null],
		mailingCity: [null],
		mailingStateGUID: [null],
		mailingZipCode: [null],
	});
	isMailingAddressSameAsPhysicalAdd: boolean | null | undefined = true;
	public addressAutocompleteOptions!: GooglePlaceAutocompleteOptions;
	allStates: State[] = [];

	addressLine1!: string;
	addressLine2!: string;

	mailingAddressLine1!: string;
	mailingAddressLine2!: string;

	searchModeOption = 'contains';

	searchExprOption: any = 'label';

	searchTimeoutOption = 200;

	minSearchLengthOption = 0;

	showDataBeforeSearchOption = false;

	searchExprOptionItems: Array<any> = [
		{
			name: "'label'",
			value: 'label',
		},
		{
			name: "['label', 'shortLabel']",
			value: ['label', 'shortLabel'],
		},
	];

	allCountryInfos: CountryInfo[] = [];

	// This is for full footer (all 3 columns)
	fullFooterLayout: boolean = true;

	// This is one column (only continue button)
	trimmedFooter: boolean = false;

	@HostListener('window:load', ['$event'])
	onLoad() {
		if (window.innerWidth <= 500) {
			this.fullFooterLayout = false;
			this.trimmedFooter = true;
		} else {
			this.fullFooterLayout = true;
			this.trimmedFooter = false;
		}
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize(event: any) {
		if (window.innerWidth <= 500) {
			this.fullFooterLayout = false;
			this.trimmedFooter = true;
		} else {
			this.fullFooterLayout = true;
			this.trimmedFooter = false;
		}
	}

	@ViewChild('application2FormValidationGroup', { static: false }) application2FormValidationGroup!: DxValidationGroupComponent;
	constructor(
		formBuilder: UntypedFormBuilder,
		activatedRoute: ActivatedRoute,
		errorHandlingService: ErrorHandlingService,
		spinnerService: NgxSpinnerService,
		router: Router,
		debitCardApplicationService: DebitCardApplicationService,
		store: DebitCardApplicationDataStore,
		masterDataService: MasterDataService,
		private tokenProviderService: TokenProviderService,
		private branchLocator: BranchLocatorService
	) {
		super(formBuilder, errorHandlingService, spinnerService, store, router, activatedRoute, debitCardApplicationService, masterDataService);
		this.validateRequiredProps = this.validateRequiredProps.bind(this);
	}

	async ngOnInit(): Promise<void> {
		this.allCountryInfos = await this.masterDataService.fetchAllCountryInfo();
		this.toggleSpinner(SpinnerState.Show);

		this.addressAutocompleteOptions = {
			types: [],
			options: {
				height: '50px',
			},
			overridableCountries: this.allCountryInfos.map((x) => x.countryShortLabel),
		};

		await this.masterDataService
			.fetchAllState()
			.then(async (response: State[]) => {
				this.toggleSpinner(SpinnerState.Hide);
				this.allStates = response;
				if (this.selectedDebitCardApplication) {
					this.bindFormValue();
				}
			})
			.catch((error) => {
				this.toggleSpinner(SpinnerState.Hide);
				this.errorHandlingService.showErrorMessage('Application failed to load, please try back after some time!');
			});
	}

	bindFormValue() {
		if (this.selectedDebitCardApplication.addressLine) {
			this.addressLine1 = this.selectedDebitCardApplication.addressLine;
		}
		this.mailingAddressLine1 = this.selectedDebitCardApplication.mailingAddress ?? '';
		this.application2Form.controls.addressLine1.setValue(this.selectedDebitCardApplication.addressLine);
		this.application2Form.controls.addressLine2.setValue(this.selectedDebitCardApplication.addressLine2);
		this.application2Form.controls.city.setValue(this.selectedDebitCardApplication.city);
		this.application2Form.controls.stateGUID.setValue(this.selectedDebitCardApplication.stateGUID);
		this.application2Form.controls.zipCode.setValue(this.selectedDebitCardApplication.zipCode);
		this.application2Form.controls.mailingAddressLine1.setValue(this.selectedDebitCardApplication.mailingAddress);
		this.application2Form.controls.mailingAddressLine2.setValue(this.selectedDebitCardApplication.mailingAddressLine2);
		this.application2Form.controls.mailingCity.setValue(this.selectedDebitCardApplication.mailingCity);
		this.application2Form.controls.mailingStateGUID.setValue(this.selectedDebitCardApplication.mailingStateGUID);
		this.application2Form.controls.mailingZipCode.setValue(this.selectedDebitCardApplication.mailingZip);
	}

	onIsMailingSameAsPhysicalAddChange(value: any) {
		this.isMailingAddressSameAsPhysicalAdd = !this.isMailingAddressSameAsPhysicalAdd;
	}

	async navigateToApplication3() {
		this.operationMode = FormOperationMode.Save;
		const validationResult = this.application2FormValidationGroup.instance.validate();
		if (validationResult.isValid) {
			this.toggleSpinner(SpinnerState.Show);
			const matchingState = this.allStates.find((x) => x.stateGUID === this.application2Form.controls.stateGUID.value);
			const nearestBranchInfo = await this.branchLocator.getNearestBranch(this.userPrimaryAddressInfo);
			const updateDebitCardApplicationRequest: DebitCardApplicationInfo = {
				addressLine: this.application2Form.controls.addressLine1.value,
				addressLine2: this.application2Form.controls.addressLine2.value,
				city: this.application2Form.controls.city.value,
				stateGUID: this.application2Form.controls.stateGUID.value,
				zipCode: this.application2Form.controls.zipCode.value,
				mailingAddress: this.isMailingAddressSameAsPhysicalAdd ? this.application2Form.controls.addressLine1.value : this.application2Form.controls.mailingAddressLine1.value,
				mailingAddressLine2: this.isMailingAddressSameAsPhysicalAdd ? this.application2Form.controls.addressLine2.value : this.application2Form.controls.mailingAddressLine2.value,
				mailingCity: this.isMailingAddressSameAsPhysicalAdd ? this.application2Form.controls.city.value : this.application2Form.controls.mailingCity.value,
				mailingStateGUID: this.isMailingAddressSameAsPhysicalAdd ? this.application2Form.controls.stateGUID.value : this.application2Form.controls.mailingStateGUID.value,
				mailingZip: this.isMailingAddressSameAsPhysicalAdd ? this.application2Form.controls.zipCode.value : this.application2Form.controls.mailingZipCode.value,
				stateLabel: matchingState?.label,
				stateShortLabel: matchingState?.shortLabel,
				countryLabel: matchingState?.countryShortLabel,
				nearestBranchAddressInfo: nearestBranchInfo,
				nearestBranchGUID: nearestBranchInfo.branchInfo.branchGUID,
			};
			await this.store.updateStatus(DebitCardApplicationStatus.IN_PROCESS_DEMOGRAPHICS);
			await this.store.patchValues(updateDebitCardApplicationRequest);
			await this.store
				.push()
				.then(async (response) => {
					this.toggleSpinner(SpinnerState.Hide);
					this.errorHandlingService.showSuccessMessage('Successfully saved application');
					this.routeSelector('debitcardppplication/application3');
				})
				.catch((error) => {
					this.toggleSpinner(SpinnerState.Hide);
					this.errorHandlingService.showErrorMessage('Failed to save application');
				});
		}
	}

	validateRequiredProps(e: any) {
		if (this.operationMode === FormOperationMode.Save) {
			return e.value != null && e.value != '';
		}
		return true;
	}

	/**
	 * This event is called when user selects an google autocomplete address option value
	 * @param addressElement
	 */
	public onAddressLine1Selected(addressElement: AddressInfo) {
		if (addressElement) {
			const matchingState = this.allStates.find((x) => x.shortLabel === addressElement.state);
			if (matchingState) this.application2Form.controls.stateGUID.setValue(matchingState.stateGUID);
			this.application2Form.controls.addressLine1.setValue(addressElement.address);
			this.application2Form.controls.city.setValue(addressElement.city);
			this.application2Form.controls.zipCode.setValue(addressElement.zip);
			this.userPrimaryAddressInfo = addressElement;
		}
	}

	/**
	 * This event is called when user select some raw address value. In such cases do not have info about
	 * state, city or zip.
	 * @param rawAddress
	 */
	public onAddressLine1ValueChange(rawAddress: any) {
		if (rawAddress) {
			this.application2Form.controls.addressLine1.setValue(rawAddress.address);
		}
	}

	/**
	 * This event is called when user selects an google autocomplete address option value
	 * @param addressElement
	 */
	public onAddressLine2Selected(addressElement: AddressInfo) {
		if (addressElement) {
			this.application2Form.controls.addressLine2.setValue(addressElement.address);
		}
	}

	/**
	 * This event is called when user select some raw address value. In such cases do not have info about
	 * state, city or zip.
	 * @param rawAddress
	 */
	public onAddressLine2ValueChange(rawAddress: any) {
		if (rawAddress) {
			this.application2Form.controls.addressLine2.setValue(rawAddress.address);
		}
	}

	/**
	 * This event is called when user selects an google autocomplete address option value
	 * @param addressElement
	 */
	public onMailingAddressLine1Selected(addressElement: AddressInfo) {
		if (addressElement) {
			const matchingState = this.allStates.find((x) => x.shortLabel === addressElement.state);
			if (matchingState) this.application2Form.controls.mailingStateGUID.setValue(matchingState.stateGUID);
			this.application2Form.controls.mailingAddressLine1.setValue(addressElement.address);
			this.application2Form.controls.mailingCity.setValue(addressElement.city);
			this.application2Form.controls.mailingZipCode.setValue(addressElement.zip);
		}
	}

	/**
	 * This event is called when user select some raw address value. In such cases do not have info about
	 * state, city or zip.
	 * @param rawAddress
	 */
	public onMailingAddressLine1ValueChange(rawAddress: any) {
		if (rawAddress) {
			this.application2Form.controls.mailingAddressLine1.setValue(rawAddress.address);
		}
	}

	/**
	 * This event is called when user selects an google autocomplete address option value
	 * @param addressElement
	 */
	public onMailingAddressLine2Selected(addressElement: AddressInfo) {
		if (addressElement) {
			this.application2Form.controls.mailingAddressLine2.setValue(addressElement.address);
		}
	}

	/**
	 * This event is called when user select some raw address value. In such cases do not have info about
	 * state, city or zip.
	 * @param rawAddress
	 */
	public onMailingAddressLine2ValueChange(rawAddress: any) {
		if (rawAddress) {
			this.application2Form.controls.mailingAddressLine2.setValue(rawAddress.address);
		}
	}
}

export enum FormOperationMode {
	Save = 'Save',
	Cancel = 'Cancel',
}
