import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorHandlingService } from '../../../../../../../goldstar-share/src/app/services/error-handling.service';
import { DebitCardApplicationDataStore } from '../../services/debit-card-application-data.store';
import { DebitCardApplicationInfo, DebitCardApplicationStatus, SpinnerState, dxUsMonthYearDateFormat } from '../../models/model';
import { DebitCardApplicationService } from '../../services/debit-card-application.service';
import { MasterDataService } from '../../services/master-data.service';
import { FormOperationMode } from '../debit-card-application2/debit-card-application2.component';
import { EditorStyle, LabelMode } from 'devextreme/common';

@Component({
	selector: 'app-base-application',
	templateUrl: './base-application.component.html',
	styleUrls: ['./base-application.component.scss'],
})
export class BaseApplicationComponent implements OnInit {
	applicationStatus!: DebitCardApplicationStatus;

	dateTimeFormat = dxUsMonthYearDateFormat;
	selectedDebitCardApplication!: DebitCardApplicationInfo;
	selectedApplicationGUID!: string;
	stylingMode: EditorStyle = 'outlined';
	labelMode: LabelMode = 'hidden';
	showSpinner!: boolean;
	operationMode: FormOperationMode | null = null;

	constructor(
		protected formBuilder: UntypedFormBuilder,
		protected errorHandlingService: ErrorHandlingService,
		protected spinnerService: NgxSpinnerService,
		protected store: DebitCardApplicationDataStore,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected debitCardApplicationService: DebitCardApplicationService,

		protected masterDataService: MasterDataService
	) {
		this.activatedRoute.queryParams.subscribe(async (response) => {
			const value = await this.store.getValue();
			this.selectedDebitCardApplication = value;
		});

		this.activatedRoute.url.subscribe(async (urlParts) => {
			if (urlParts.length >= 2) {
				const pathParam = urlParts[1].path;
				if (pathParam) {
					this.selectedApplicationGUID = pathParam;
				}
			}
			const application = await this.store.getValue();
			if (application) {
				this.selectedDebitCardApplication = application;
			}
		});
	}
	ngOnInit(): void {}

	toggleSpinner(state: SpinnerState) {
		switch (state) {
			case SpinnerState.Show:
				this.spinnerService.show();
				this.showSpinner = true;
				break;
			case SpinnerState.Hide:
			default:
				this.spinnerService.hide();
				this.showSpinner = false;
				break;
		}
	}
	async routeSelectorBasedOnApplicationStatus() {
		if (this.selectedDebitCardApplication) {
			const matchingStatus = (await this.masterDataService.fetchAllStatus()).filter((x) => x.applicationStatusGUID === this.selectedDebitCardApplication.applicationStatusGUID);
			if (matchingStatus && matchingStatus.length >= 1) {
				const applicationStatus = DebitCardApplicationStatus[matchingStatus[0].applicationStatusSystemCode as keyof typeof DebitCardApplicationStatus];
				switch (applicationStatus) {
					case DebitCardApplicationStatus.IN_PROCESS_ADDRESS:
						this.routeSelector('debitcardppplication/application2');
						break;
					case DebitCardApplicationStatus.IN_PROCESS_DEMOGRAPHICS:
						this.routeSelector('debitcardppplication/application3');
						break;
					case DebitCardApplicationStatus.IN_PROCESS_IDENTITY_VERIFICATION:
						if (this.selectedDebitCardApplication.userIdentityInfoGUID && !this.selectedDebitCardApplication.identityVerifiedYN) {
							await this.store.resetApplication();
							await this.store.updateStatus(DebitCardApplicationStatus.IN_PROCESS_ADDRESS);
							this.routeSelector('debitcardppplication/application2');
						} else {
							this.routeSelector('debitcardppplication/applicationSummary');
						}
						break;
					case DebitCardApplicationStatus.IN_PROCESS_AUTHORIZATION:
						this.routeSelector('debitcardppplication/authorization');
						break;
				}
			}
		}
	}
	routeSelector(existingRoute: string) {
		if (this.selectedApplicationGUID) {
			this.router.navigate([`${existingRoute}/${this.selectedApplicationGUID}`]);
		} else {
			this.router.navigate([`${existingRoute}`]);
		}
	}
}
