import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { EditorStyle, LabelMode } from 'devextreme/common';

@Component({
	selector: 'app-debit-card-application-edit',
	templateUrl: './debit-card-application-edit.component.html',
	styleUrls: ['./debit-card-application-edit.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DebitCardApplicationEditComponent implements OnInit {
	stylingMode: EditorStyle = 'outlined';
	labelMode: LabelMode = 'hidden';
	constructor(private router: Router) {}

	ngOnInit(): void {}

	navigateToDashboard() {
		this.router.navigate(['/debitcardppplication/dashboard']);
	}
}
