import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../../../goldstar-client/src/environments/environment';
import { NavigationState, Screen } from '../../models/model';
import { Router } from '@angular/router';

@Component({
	selector: 'app-debit-card-application-dashboard',
	templateUrl: './debit-card-application-dashboard.component.html',
	styleUrls: ['./debit-card-application-dashboard.component.scss'],
})
export class DebitCardApplicationDashboardComponent implements OnInit {
	public navigationState: NavigationState = {
		activeScreen: Screen.Dashboard,
	};
	menuItemHoverImagePath!: string;

	showHoverImageForNewCustomers!: boolean;
	showHoverImageForExistingCustomers!: boolean;

	newCustomerBoxContainerStyle: string = 'box-container';
	existingCustomerBoxContainerStyle: string = 'box-container';

	newCustomerButtonColor = 'primary';

	existingCustomerButtonColor = 'primary';

	constructor(private router: Router) {
		// this is needed as in the deployed site the url does not ends with trailing '/' character.
		this.menuItemHoverImagePath = environment.clientAppUri.endsWith('/') ? `${environment.clientAppUri}favicon.ico` : `${environment.clientAppUri}/favicon.ico`;
		console.log('The client app image uri is ----', this.menuItemHoverImagePath);
		this.navigationState.activeScreen = Screen.Dashboard;
	}
	ngOnInit(): void {}

	navigateToNewCustomerScreen() {
		this.newCustomerButtonColor = 'primary';
		this.navigationState.activeScreen = Screen.NewCustomer;
	}

	navigateToDashboard() {
		this.navigationState.activeScreen = Screen.Dashboard;
	}

	async navigateToEditApplication() {
		this.router.navigate(['/debitcardppplication/debitCardApplicationEdit']);
	}

	onMouseOverOnNewCustomerButton() {
		this.newCustomerBoxContainerStyle = 'box-container-hover';
	}

	onMouseOutOnNewCustomerButton() {
		this.newCustomerBoxContainerStyle = 'box-container';
	}

	onMouseOverOnExistingCustomerButton() {
		this.existingCustomerBoxContainerStyle = 'box-container-hover';
	}

	onMouseOutOnExistingCustomerButton() {
		this.existingCustomerBoxContainerStyle = 'box-container';
	}
}
