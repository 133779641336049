import { AfterViewInit, Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandlingService } from '../../../../../../../goldstar-share/src/app/services/error-handling.service';
import { TokenProviderService } from '../../services/token-provider.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DxValidationGroupComponent } from 'devextreme-angular';
import { KEY_CODE, Result, UserInfo } from '../../../../../../../goldstar-share/src/app/models/models';
import {
	UserOtpValidationRequest,
	OldDebitCardApplicationRequest,
	DebitCardApplicationItem,
	DebitCardApplicationRequest,
	DebitCardApplication,
	GenerateOtpRequest,
} from '../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { SpinnerState, ICustomDialogComponentData, DialogBtnAction, DebitCardApplicationStatus } from '../../models/model';
import { DebitCardApplicationDataStore } from '../../services/debit-card-application-data.store';
import { MasterDataService } from '../../services/master-data.service';
import { DebitCardApplicationService } from '../../services/debit-card-application.service';
import { MatDialog } from '@angular/material/dialog';
import { BaseApplicationComponent } from '../base-application/base-application.component';
import { CustomClientDialogComponent } from '../../../../../../../goldstar-share/src/app/components/shared/custom-client-dialog/custom-client-dialog.component';
import { NgxOtpInputComponent, NgxOtpInputConfig } from 'ngx-otp-input';

@Component({
	selector: 'app-otp-validation',
	templateUrl: './otp-validation.component.html',
	styleUrls: ['./otp-validation.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class OtpValidationComponent extends BaseApplicationComponent implements OnInit {
	last4DigitOfPhoneNumber!: string;
	otpValidationForm!: UntypedFormGroup;
	serviceInstanceId!: string;
	showOtpValidation: boolean = false;
	numberBox1Format: string = '#,##0.##';
	otpForVerification!: string;

	/** Ngx Otp control config */
	otpInputConfig: NgxOtpInputConfig = {
		// classList: {
		// 	input: 'otp-input',
		// },
		otpLength: 4,
		autofocus: true,
		autoblur: true,
		isPasswordInput: false,
		ariaLabels: ['a', 'b', 'v', 'c'],
	};

	// This is for full footer (all 3 columns)
	fullFooterLayout: boolean = true;

	// This is one column (only continue button)
	trimmedFooter: boolean = false;

	@HostListener('window:load', ['$event'])
	onLoad() {
		if (window.innerWidth <= 500) {
			this.fullFooterLayout = false;
			this.trimmedFooter = true;
		} else {
			this.fullFooterLayout = true;
			this.trimmedFooter = false;
		}
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize(event: any) {
		if (window.innerWidth <= 500) {
			this.fullFooterLayout = false;
			this.trimmedFooter = true;
		} else {
			this.fullFooterLayout = true;
			this.trimmedFooter = false;
		}
	}

	@ViewChild('otpValidationFormValidationGroup', { static: false }) otpValidationFormValidationGroup!: DxValidationGroupComponent;

	@ViewChild('ngxOtp') ngxOtp!: NgxOtpInputComponent;
	constructor(
		formBuilder: UntypedFormBuilder,
		activatedRoute: ActivatedRoute,
		errorHandlingService: ErrorHandlingService,
		spinnerService: NgxSpinnerService,
		router: Router,
		debitCardApplicationService: DebitCardApplicationService,
		store: DebitCardApplicationDataStore,
		masterDataService: MasterDataService,
		private dialog: MatDialog,
		private tokenProviderService: TokenProviderService
	) {
		super(formBuilder, errorHandlingService, spinnerService, store, router, activatedRoute, debitCardApplicationService, masterDataService);
	}
	async ngOnInit(): Promise<void> {
		this.toggleSpinner(SpinnerState.Show);
		this.otpForVerification = '';
		this.initializeForm();
		if (this.selectedApplicationGUID) {
			await this.fetchSelectedDebitCardApplication()
				.then((response) => {
					if (response.isSuccess && response.data) {
						const otpVerificationRequest: GenerateOtpRequest = {
							countryCode: this.selectedDebitCardApplication.countryCode,
							phoneNumber: this.selectedDebitCardApplication.primaryPhone,
							verificationMode: 'sms',
						};
						this.debitCardApplicationService
							.sendVerificationCode(otpVerificationRequest)
							.then(async (response) => {
								if (response.isSuccess) {
									this.toggleSpinner(SpinnerState.Hide);
									this.serviceInstanceId = response.data ?? '';
									this.last4DigitOfPhoneNumber = this.selectedDebitCardApplication.primaryPhone?.slice(-4) ?? '';
									this.errorHandlingService.showSuccessMessage('Successfully send verification code');
								} else {
									this.toggleSpinner(SpinnerState.Hide);
									this.errorHandlingService.showErrorMessage('Error sending verification code, please verify the number and try again');
								}
							})
							.catch((error) => {
								this.toggleSpinner(SpinnerState.Hide);
								this.errorHandlingService.showErrorMessage('Failed to send verification code');
							});
					}
				})
				.catch((error) => {
					this.toggleSpinner(SpinnerState.Hide);
					this.errorHandlingService.showErrorMessage('Error parsing the request');
				});
		} else {
			this.toggleSpinner(SpinnerState.Hide);
			this.last4DigitOfPhoneNumber = this.selectedDebitCardApplication.primaryPhone?.slice(-4) ?? '';
		}
	}
	initializeForm() {
		this.otpValidationForm = this.formBuilder.group({
			verificationDigit1: [null, Validators.required],
			verificationDigit2: [null, Validators.required],
			verificationDigit3: [null, Validators.required],
			verificationDigit4: [null, Validators.required],
			verificationDigit5: [null, Validators.required],
			verificationDigit6: [null, Validators.required],
		});
	}

	/**
	 * Event is called on otp entered, for e.g. If the length of the otp is 4 digit then this event is called
	 * when all the 4 digit have been entered
	 * @param $event : string value of the Otp
	 */
	onOtpValueChange($event: any): void {
		this.showOtpValidation = !$event || $event == '' || $event.length < 4;
		this.otpForVerification = $event;
	}
	async sendCodeOverSMS() {
		if (this.showOtpValidation) {
			return;
		}
		this.toggleSpinner(SpinnerState.Show);
		const otpVerificationRequest: GenerateOtpRequest = {
			countryCode: this.selectedDebitCardApplication.countryCode,
			phoneNumber: this.selectedDebitCardApplication.primaryPhone,
			verificationMode: 'sms',
		};
		await this.sendGenerateOtpRequest(otpVerificationRequest);
	}
	async sendCodeOverCall() {
		if (this.showOtpValidation) {
			return;
		}
		this.toggleSpinner(SpinnerState.Show);
		const otpVerificationRequest: GenerateOtpRequest = {
			countryCode: this.selectedDebitCardApplication.countryCode,
			phoneNumber: this.selectedDebitCardApplication.primaryPhone,
			verificationMode: 'call',
		};
		await this.sendGenerateOtpRequest(otpVerificationRequest);
	}
	private async sendGenerateOtpRequest(otpVerificationRequest: GenerateOtpRequest) {
		this.debitCardApplicationService
			.sendVerificationCode(otpVerificationRequest)
			.then((response) => {
				if (response.isSuccess) {
					this.toggleSpinner(SpinnerState.Hide);
					this.serviceInstanceId = response.data ?? '';
					this.errorHandlingService.showSuccessMessage('Successfully re-send verification code');
				} else {
					this.toggleSpinner(SpinnerState.Hide);
					this.errorHandlingService.showErrorMessage('Error sending verification code');
				}
			})
			.catch((error) => {
				this.toggleSpinner(SpinnerState.Hide);
				this.errorHandlingService.showErrorMessage('Failed to re-send verification code');
			});
	}
	async navigateToApplication2() {
		const isValidForm = this.validateForm();
		if (isValidForm) {
			this.toggleSpinner(SpinnerState.Show);

			// Checks for token authorization
			const userInfo: UserInfo = {
				firstName: this.selectedDebitCardApplication.firstName ?? '',
				middleName: this.selectedDebitCardApplication.middleName ?? '',
				lastName: this.selectedDebitCardApplication.lastName ?? '',
				phoneNumber: this.selectedDebitCardApplication.primaryPhone ?? '',
				verificationCode: this.otpForVerification,
			};

			const otpValidationRequest: UserOtpValidationRequest = {
				countryCode: this.selectedDebitCardApplication.countryCode,
				userInfo: userInfo,
				serviceInstanceId: this.serviceInstanceId,
				otp: this.otpForVerification,
			};

			// Fetch the token from the server
			const fetchTokenResponse = await this.tokenProviderService.fetchToken(otpValidationRequest);
			if (!fetchTokenResponse.isSuccess) {
				this.toggleSpinner(SpinnerState.Hide);
				this.errorHandlingService.showErrorMessage('Otp validation failed, please try again.');
				return;
			}

			this.errorHandlingService.showSuccessMessage('Successfully validated Otp');

			try {
				// Initializes all the mater data setup request
				await this.masterDataService.initialize();

				// Fetches the data from the API server
				await this.fetchSelectedDebitCardApplication()
					.then(async (response) => {
						if (!response.isSuccess) throw Error('Failed to load data, please try back after some time!');
						if (response.isSuccess && response.data && response.data.length >= 1) {
							this.toggleSpinner(SpinnerState.Hide);
							this.errorHandlingService.showSuccessMessage('Successfully fetched existing debit card application');
							if (this.selectedDebitCardApplication && this.selectedDebitCardApplication.userIdentityInfoGUID && !this.selectedDebitCardApplication.identityVerifiedYN)
								return await this.routeSelectorBasedOnApplicationStatus();
							if (this.selectedApplicationGUID) return await this.routeSelectorBasedOnApplicationStatus();
							else {
								let dialogRef = this.dialog.open(CustomClientDialogComponent, {
									width: '40%',
									height: '30%',
									data: {
										title: 'Confirmation',
										body: 'We currently have an in-process application for you.',
										confirmBtnLabel: 'Continue Existing Application',
										cancelBtnLabel: 'Start a New Application',
									},
									backdropClass: 'backdropBackground', // This is need for the overlay background, defined in client style.scss
								});
								dialogRef.afterClosed().subscribe(async (response: ICustomDialogComponentData) => {
									// this.toggleSpinner(SpinnerState.Show);
									switch (response.btnAction) {
										case DialogBtnAction.Confirm:
											console.log('Confirmation received');
											// await this.store.updateStatus(DebitCardApplicationStatus.IN_PROCESS_ADDRESS);
											this.routeSelectorBasedOnApplicationStatus();
											break;
										case DialogBtnAction.Cancel:
											this.toggleSpinner(SpinnerState.Show);
											const oldApplicationGUID = (await this.store.getValue()).debitCardApplicationGUID;
											await this.store.resetApplication();
											await this.store.updateStatus(DebitCardApplicationStatus.IN_PROCESS_ADDRESS);
											const newApplication = await this.store.getValue();
											const overrideAndCreateNewApplicationRequest: OldDebitCardApplicationRequest = {
												debitCardApplicationGUID: oldApplicationGUID ?? '',
												debitCardApplicationItem: newApplication,
											};
											await this.debitCardApplicationService
												.overrideAndAddDebitCardApplication(overrideAndCreateNewApplicationRequest)
												.then(async (response) => {
													this.store.replaceValue(response[0]);
													this.toggleSpinner(SpinnerState.Hide);
													this.errorHandlingService.showSuccessMessage('New application successfully created');
													this.routeSelector('debitcardppplication/application2');
												})
												.catch((error) => {
													this.toggleSpinner(SpinnerState.Hide);
													this.errorHandlingService.showErrorMessage(error);
												});
											break;
									}
								});
							}
						} else {
							await this.store.updateStatus(DebitCardApplicationStatus.IN_PROCESS_ADDRESS);
							await this.store
								.push()
								.then(async (response) => {
									this.toggleSpinner(SpinnerState.Hide);
									const updateDebitCardApplicationItem: DebitCardApplicationItem = {
										debitCardApplicationGUID: response,
									};
									this.store.patchValues(updateDebitCardApplicationItem);
									this.errorHandlingService.showSuccessMessage('Successfully created new debit card application');
									this.routeSelector('debitcardppplication/application2');
								})
								.catch((error) => {
									this.toggleSpinner(SpinnerState.Hide);
									this.errorHandlingService.showErrorMessage('Failed to create new debit card application');
								});
						}
					})
					.catch((error) => {
						this.toggleSpinner(SpinnerState.Hide);
						this.errorHandlingService.showErrorMessage(error);
					});
			} catch (error: any) {
				this.toggleSpinner(SpinnerState.Hide);
				this.errorHandlingService.showErrorMessage(error);
			}
		}
	}
	validateForm(): boolean {
		const validationResult = this.otpValidationFormValidationGroup.instance.validate();
		const isValid = validationResult && this.otpForVerification.length == 4;
		this.showOtpValidation = !isValid;
		return isValid;
	}
	async fetchSelectedDebitCardApplication(): Promise<Result<DebitCardApplication[]>> {
		let applicationRequest: DebitCardApplicationRequest = {};
		if (this.selectedApplicationGUID) {
			applicationRequest = {
				debitCardApplicationGUID: this.selectedApplicationGUID,
			};
		} else {
			applicationRequest = {
				firstName: this.selectedDebitCardApplication.firstName ?? '',
				lastName: this.selectedDebitCardApplication.lastName ?? '',
				phoneNumber: this.selectedDebitCardApplication.primaryPhone ?? '',
				emailAddress: this.selectedDebitCardApplication.primaryEmail ?? '',
			};
		}
		return await this.store
			.fetchValue(applicationRequest)
			.then((response) => {
				if (response.isSuccess && response.data) {
					this.selectedDebitCardApplication = response.data[0];
				}
				return response;
			})
			.catch((error) => {
				throw error;
			});
	}
}
