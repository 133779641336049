<ngx-spinner *ngIf="showSpinner" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
	<p style="color: white">Loading...</p>
</ngx-spinner>
<div [fluidHeight] class="row col-lg-12 col-md-12 application-container">
	<div class="header">
		<div class="row col-lg-12 col-md-12">
			<div class="header-image-container">
				<img class="header-image" src="../../../assets/images/Trb_Bank_Logo_with_caption.png" />
			</div>
		</div>
		<div class="padding-top-large">
			<section>
				<h1 class="form-header-title">Let's get to know you</h1>
			</section>
			<section class="padding-top-xl">
				<h2 class="padding-left-small form-header-subtitle">Application - Section 2</h2>
				<div class="padding-top-large padding-left-small">
					<form [formGroup]="application2Form">
						<dx-validation-group #application2FormValidationGroup>
							<div class="col-lg-4 col-md-2 d-sm-none d-md-block"></div>
							<div class="row row-padding">
								<div class="col-lg-6 col-md-6 row-content-padding">
									<div style="display: flex; flex-direction: row; align-items: center">
										<span class="form-field-label">Physical Address (should match your ID)</span>
										<span class="form-field-label-required italic-font">(required)</span>
									</div>
									<!-- <app-address-autocomplete
										[appControlHeight]
										validationMessageMode="always"
										[label]="'Primary Address'"
										[stylingMode]="stylingMode"
										[labelMode]="labelMode"
										(onPlaceSelect)="onAddressLine1Selected($event)"
										(onValueChange)="onAddressLine1ValueChange($event)"
										[autoCompleteValue]="addressLine1"
										[autocompleteOptions]="addressAutocompleteOptions"
										[isRequired]="true"
										[requiredMessage]="'Street Address is required'"
									></app-address-autocomplete> -->
								</div>
							</div>
							<div class="row row-padding">
								<div class="col-lg-6 col-md-6 row-content-padding">
									<span class="form-field-label">Physical Address Line 2</span>
									<dx-text-box
										[appControlHeight]
										class="form-field-margin-top"
										validationMessageMode="always"
										label="'Address Line 2'"
										formControlName="addressLine2"
										[stylingMode]="stylingMode"
										[labelMode]="labelMode"
									>
									</dx-text-box>
								</div>
							</div>
							<div class="row row-padding">
								<div class="col-lg-4 col-md-4 row-content-padding">
									<div style="display: flex; flex-direction: row; align-items: center">
										<span class="form-field-label">City </span>
										<span class="form-field-label-required italic-font">(required)</span>
									</div>
									<dx-text-box
										class="form-field-margin-top"
										validationMessageMode="always"
										[appControlHeight]
										label="City"
										formControlName="city"
										[stylingMode]="stylingMode"
										[labelMode]="labelMode"
									>
										<dx-validator>
											<dxi-validation-rule type="required" message="City is required"> </dxi-validation-rule>
										</dx-validator>
									</dx-text-box>
								</div>
								<div class="col-lg-4 col-md-4 row-content-padding">
									<div style="display: flex; flex-direction: row; align-items: center">
										<span class="form-field-label">State </span>
										<span class="form-field-label-required italic-font">(required)</span>
									</div>
									<dx-select-box
										class="form-field-margin-top"
										validationMessageMode="always"
										[items]="allStates"
										[appControlHeight]
										formControlName="stateGUID"
										displayExpr="label"
										valueExpr="stateGUID"
										label="State"
										[stylingMode]="stylingMode"
										[labelMode]="labelMode"
										[searchEnabled]="true"
										searchExpr="label"
										searchMode="contains"
									>
										<dx-validator>
											<dxi-validation-rule type="required" message="State is required"> </dxi-validation-rule>
										</dx-validator>
									</dx-select-box>
								</div>
								<div class="col-lg-4 col-md-4 row-content-padding">
									<div style="display: flex; flex-direction: row; align-items: center">
										<span class="form-field-label">Zip Code </span>
										<span class="form-field-label-required italic-font">(required)</span>
									</div>

									<dx-text-box
										class="form-field-margin-top"
										validationMessageMode="always"
										[appControlHeight]
										label="Zip Code"
										formControlName="zipCode"
										[stylingMode]="stylingMode"
										[labelMode]="labelMode"
									>
										<dx-validator>
											<dxi-validation-rule
												type="required"
												message="Your Zip code is outside our service area. If you are moving to a community with a Texas Regional Bank, please contact us for assistance"
											>
											</dxi-validation-rule>
										</dx-validator>
									</dx-text-box>
								</div>
							</div>
							<div class="row row-padding">
								<div class="btn-container">
									<dx-check-box
										class="approve-content-wrapper-checkbox"
										[iconSize]="22"
										[value]="isMailingAddressSameAsPhysicalAdd"
										(onValueChanged)="onIsMailingSameAsPhysicalAddChange($event)"
									></dx-check-box>
									<span class="approve-content-wrapper">This is also my mailing address</span>
								</div>
							</div>
							<div *ngIf="!isMailingAddressSameAsPhysicalAdd" class="row row-padding">
								<div class="col-lg-6 col-md-6 row-content-padding">
									<div style="display: flex; flex-direction: row; align-items: center">
										<span class="form-field-label">Mailing Address</span>
										<span class="form-field-label-required italic-font">(required)</span>
									</div>
									<!-- <app-address-autocomplete
										validationMessageMode="always"
										[appControlHeight]
										[label]="'Mailing Address Line1'"
										[stylingMode]="stylingMode"
										[labelMode]="labelMode"
										(onPlaceSelect)="onMailingAddressLine1Selected($event)"
										(onValueChange)="onMailingAddressLine1ValueChange($event)"
										[autoCompleteValue]="mailingAddressLine1"
										[autocompleteOptions]="addressAutocompleteOptions"
										[isRequired]="true"
										[requiredMessage]="'Street Address is required'"
									></app-address-autocomplete> -->
								</div>
							</div>
							<div *ngIf="!isMailingAddressSameAsPhysicalAdd" class="row row-padding">
								<div class="col-lg-6 col-md-6 row-content-padding">
									<span class="form-field-label">Mailing Address Line 2</span>
									<dx-text-box
										class="form-field-margin-top"
										validationMessageMode="always"
										[appControlHeight]
										label="'Address Line 2'"
										formControlName="mailingAddressLine2"
										[stylingMode]="stylingMode"
										[labelMode]="labelMode"
									>
									</dx-text-box>
								</div>
							</div>
							<div *ngIf="!isMailingAddressSameAsPhysicalAdd" class="row">
								<div class="col-lg-4 col-md-4 row-content-padding">
									<div style="display: flex; flex-direction: row; align-items: center">
										<span class="form-field-label">City </span>
										<span class="form-field-label-required italic-font">(required)</span>
									</div>
									<dx-text-box
										class="form-field-margin-top"
										validationMessageMode="always"
										[appControlHeight]
										label="City"
										formControlName="mailingCity"
										[stylingMode]="stylingMode"
										[labelMode]="labelMode"
									>
										<dx-validator>
											<dxi-validation-rule type="custom" [reevaluate]="true" [validationCallback]="validateRequiredProps" message="City is required"> </dxi-validation-rule>
										</dx-validator>
									</dx-text-box>
								</div>
								<div class="col-lg-4 col-md-4 row-content-padding">
									<div style="display: flex; flex-direction: row; align-items: center">
										<span class="form-field-label">State </span>
										<span class="form-field-label-required italic-font">(required)</span>
									</div>
									<dx-select-box
										class="form-field-margin-top"
										validationMessageMode="always"
										[items]="allStates"
										[appControlHeight]
										formControlName="mailingStateGUID"
										displayExpr="label"
										valueExpr="stateGUID"
										label="State"
										[stylingMode]="stylingMode"
										[labelMode]="labelMode"
										[searchEnabled]="true"
										searchExpr="label"
										searchMode="contains"
									>
										<dx-validator>
											<dxi-validation-rule type="custom" [reevaluate]="true" [validationCallback]="validateRequiredProps" message="State is required"> </dxi-validation-rule>
										</dx-validator>
									</dx-select-box>
								</div>
								<div class="col-lg-4 col-md-4 row-content-padding">
									<div style="display: flex; flex-direction: row; align-items: center">
										<span class="form-field-label">Zip Code </span>
										<span class="form-field-label-required italic-font">(required)</span>
									</div>

									<dx-text-box
										validationMessageMode="always"
										class="form-field-margin-top"
										[appControlHeight]
										label="Mailing Zip Code"
										formControlName="mailingZipCode"
										[stylingMode]="stylingMode"
										[labelMode]="labelMode"
									>
										<dx-validator>
											<dxi-validation-rule type="custom" [reevaluate]="true" [validationCallback]="validateRequiredProps" message="Zip Code is required"> </dxi-validation-rule>
										</dx-validator>
									</dx-text-box>
								</div>
							</div>
						</dx-validation-group>
					</form>
				</div>
			</section>
		</div>
		<div class="row footer">
			<div *ngIf="fullFooterLayout" style="display: flex; flex-direction: row; justify-content: space-between">
				<div style="display: flex; flex-direction: row; align-items: center">
					<img src="../../../assets/images/lock_not_filled.svg" height="10vh" />
					<span style="font-size: 10px; color: lightgray"> Your security matters to us</span>
				</div>
				<img src="../../../assets/images/logo_watermark.png" height="30vh" />
				<button mat-button style="border: 2px solid; border-radius: 10px; background: transparent; font-size: 15px; min-width: 100px" (click)="navigateToApplication3()">Continue</button>
			</div>
			<div *ngIf="trimmedFooter" style="display: flex; flex-direction: row; justify-content: center">
				<button mat-button style="border: 2px solid; border-radius: 10px; background: transparent; font-size: 15px; min-width: 100px" (click)="navigateToApplication3()">Continue</button>
			</div>
		</div>
	</div>
</div>
