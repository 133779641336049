import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorHandlingService } from '../../../../../../../goldstar-share/src/app/services/error-handling.service';
import { S3FileService } from '../../../../../../../goldstar-share/src/app/services/s3file-service';
import { DebitCardApplicationDataStore } from '../../services/debit-card-application-data.store';
import { DebitCardApplicationService } from '../../services/debit-card-application.service';
import { MasterDataService } from '../../services/master-data.service';
import { BaseApplicationComponent } from '../base-application/base-application.component';
import { BranchLocatorService } from '../../services/branch-locator.service';
import { AddressInfo } from '../../../../../../../goldstar-share/src/app/components/shared/address-autocomplete/address-autocomplete.component';

@Component({
	selector: 'app-debit-card-identity-verification-failed',
	templateUrl: './debit-card-identity-verification-failed.component.html',
	styleUrls: ['./debit-card-identity-verification-failed.component.scss'],
})
export class DebitCardIdentityVerificationFailedComponent extends BaseApplicationComponent implements OnInit {
	public staticSiteLink = 'https://www.texasregionalbank.com/locations/wimberley/';
	constructor(
		formBuilder: UntypedFormBuilder,
		activatedRoute: ActivatedRoute,
		errorHandlingService: ErrorHandlingService,
		spinnerService: NgxSpinnerService,
		router: Router,
		debitCardApplicationService: DebitCardApplicationService,
		store: DebitCardApplicationDataStore,
		masterDataService: MasterDataService,
		private fileService: S3FileService,
		private dialog: MatDialog,

		private branchLocator: BranchLocatorService
	) {
		super(formBuilder, errorHandlingService, spinnerService, store, router, activatedRoute, debitCardApplicationService, masterDataService);
	}

	async ngOnInit(): Promise<void> {
		this.staticSiteLink = this.selectedDebitCardApplication.nearestBranchAddressInfo?.addressInfo.branchWebsiteUrl ?? '';
	}
}
