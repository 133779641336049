import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { TranslateService } from '@ngx-translate/core';
import { HelpMenuService } from '../../../goldstar-share/src/app/services/help-menu.service';
import { HeaderService } from './services/header-service';
import { ScreenSizeObserverService } from './services/screen-size-observer.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
	title = 'goldstar';

	hey = true;
	constructor(
		public translate: TranslateService,
		public helpMenuService: HelpMenuService,
		private matIconRegistry: MatIconRegistry,
		public headerService: HeaderService,

		public screenSizeObserver: ScreenSizeObserverService
	) {
		translate.addLangs(['en', 'es']);
		translate.setDefaultLang('en');

		const browserLang = translate.getBrowserLang() as string;
		translate.use(browserLang.match(/en|es/) ? browserLang : 'en');

		this.matIconRegistry.addSvgIcon;
	}

	ngOnInit(): void {
		this.megaMenuSetup();
	}
	ngAfterViewInit() {
		this.screenSizeObserver.subscribeFullScreenUpdate();
		this.screenSizeObserver.subscribeLargeScreenUpdate();
		this.screenSizeObserver.subscribeSmallScreenUpdate();
		this.screenSizeObserver.subscribeMobileScreenUpdate();
	}

	megaMenuSetup() {
		//    window.addEventListener("resize", function () {
		//      "use strict"; window.location.reload();
		//    });
		document.addEventListener('DOMContentLoaded', function () {
			// 	/////// Prevent closing from click inside dropdown
			document.querySelectorAll('.dropdown-menu').forEach(function (element) {
				element.addEventListener('click', function (e) {
					e.stopPropagation();
				});
			});
			// make it as accordion for smaller screens
			if (window.innerWidth > 992) {
				document.querySelectorAll('.navbar .nav-item').forEach(function (everyitem) {
					everyitem.addEventListener('mouseover', (e) => {
						//let el_link = document.querySelector('a[data-bs-toggle]');
						let el_link = (<any>e.currentTarget)?.querySelector('a[data-bs-toggle]');

						if (el_link != null) {
							let nextEl = el_link.nextElementSibling;
							//let nextEl = (<any>e.currentTarget)?.querySelectorAll('.dropdown-menu')[0];
							el_link.classList.add('show');
							nextEl?.classList.add('show');
						}
					});
					everyitem.addEventListener('mouseleave', (e) => {
						//let el_link = document.querySelector('a[data-bs-toggle]');
						let el_link = (<any>e.currentTarget)?.querySelector('a[data-bs-toggle]');

						if (el_link != null) {
							let nextEl = el_link.nextElementSibling;
							el_link.classList.remove('show');
							nextEl?.classList.remove('show');
						}
					});
				});
			}
			// end if innerWidth
		});
		// DOMContentLoaded  end
	}

	// switchLang(lang: string) {
	//   this.translate.use(lang);
	// }
}
