<ngx-spinner *ngIf="showSpinner" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
	<p style="color: white">Loading...</p>
</ngx-spinner>
<div [fluidHeight] class="row col-lg-12 col-md-12 application-container">
	<div class="header" style="height: inherit; display: flex; flex-direction: column; justify-content: space-between">
		<div class="row col-lg-12 col-md-12 custom-row" style="padding-top: 50px">
			<div class="header-image-container">
				<img class="header-image" src="../../../assets/images/Trb_Bank_Logo_with_caption.png" />
			</div>
		</div>
		<div *ngIf="!userIdentityVerified" style="overflow-y: hidden; margin-top: -100px; display: flex; flex-direction: column; align-items: center; justify-content: center">
			<section>
				<h1 class="header1-label" style="padding-left: 3px">{{ identityVerificationHeader }}</h1>
			</section>
			<form [formGroup]="applicationSummaryForm">
				<dx-validation-group #applicationSummaryFormValidationGroup>
					<section style="display: flex; flex-direction: row; align-items: center; padding-bottom: 40px">
						<div [ngClass]="plaidContainerStyle">
							<app-plaid-auth [config]="plaidConfig" [label]="'Begin Verification'" [templateId]="plaidVerificationTemplateId" (plaidOutput)="onPlaidOutput(1, $event)"></app-plaid-auth>
						</div>
					</section>
				</dx-validation-group>
			</form>
		</div>
		<div *ngIf="userIdentityVerified" style="overflow-y: hidden; margin-top: -100px; text-align: center">
			<section>
				<div style="display: flex; flex-direction: row; align-items: center; justify-content: center">
					<img src="../../../../../assets/images/green_solid_tick.svg" height="70vh" width="70vh" />
					<h1 style="padding-left: 3px; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 40px; font-weight: 500">Identity Successfully Verified</h1>
				</div>
			</section>
		</div>
		<div class="row footer" style="padding-right: 40px; padding-top: 10px">
			<div *ngIf="fullFooterLayout" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
				<button mat-button style="border: 2px solid; border-radius: 10px; background: transparent; font-size: 15px; min-width: 100px; margin-left: 30px" (click)="navigateBackToApplication3()">
					Previous
				</button>
				<img src="../../../assets/images/logo_watermark.png" height="30vh" />
				<button
					mat-button
					style="border: 2px solid; border-radius: 10px; background: transparent; font-size: 15px; margin-left: 15px; min-width: 100px"
					[disabled]="!userIdentityVerified"
					(click)="navigateToAuthorization()"
				>
					Continue
				</button>
			</div>
			<div *ngIf="trimmedFooter" style="display: flex; flex-direction: row; justify-content: center; align-items: center">
				<button
					mat-button
					style="border: 2px solid; border-radius: 10px; background: transparent; font-size: 15px; margin-left: 15px; min-width: 100px"
					[disabled]="!userIdentityVerified"
					(click)="navigateToAuthorization()"
				>
					Continue
				</button>
			</div>
		</div>
	</div>
</div>
