import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DebitCardApplicationService } from '../../services/debit-card-application.service';
import { ErrorHandlingService } from '../../../../../../../goldstar-share/src/app/services/error-handling.service';

@Component({
	selector: 'app-new-customer-acknowledgement',
	templateUrl: './new-customer-acknowledgement.component.html',
	styleUrls: ['./new-customer-acknowledgement.component.scss'],
})
export class NewCustomerAcknowledgementComponent implements OnInit {
	@Output() onBackBtnPressed: EventEmitter<any> = new EventEmitter();
	constructor(private router: Router, private debitCardApplicationService: DebitCardApplicationService, private errorHandling: ErrorHandlingService) {}

	ngOnInit(): void {}

	navigateToDashboard() {
		this.onBackBtnPressed.emit();
	}

	async navigateToNewApplication() {
		this.router.navigate(['debitcardppplication/login']);
		// this.router.navigate(['debitcardppplication/debitCardApplicationAdd']);
	}
}
