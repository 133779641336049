<ngx-spinner *ngIf="true" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="false">
	<p style="color: white">Loading...</p>
</ngx-spinner>
<div style="display: flex; justify-content: space-between; align-items: center; margin-top: 20px" *ngIf="fileName != ''">
	<p>{{ fileLabel }}</p>
	<button mat-icon-button aria-label="close dialog" mat-dialog-close style="margin-top: -20px" (click)="onCancel($event)">
		<mat-icon>close</mat-icon>
	</button>
</div>
<div #fileView style="width: 100%; height: 100%"></div>
<!-- <div>
	<div style="padding-top: 20px; padding-bottom: 10px; text-align: center; display: flex; flex-direction: column; align-items: center">
		<div *ngIf="showTermsAndConditionLabel">
			<dx-check-box class="approve-content-wrapper-checkbox" [iconSize]="22" [(value)]="acknowledgeTermsAndCondition" (onValueChanged)="onAcknowledgement($event)"> </dx-check-box>
			<span class="approve-content-wrapper">{{ termsAndConditionLabelContent }}</span>
		</div>
		<button mat-raised-button color="primary" [disabled]="!enableFormSubmit" style="font-size: 15px; max-width: 250px; text-align: center" (click)="onAcknowledgeFile($event)">
			{{ fileAcknowledgeBtnLabel }}
		</button>
	</div>
	<div class="separator" style="color: #2196f3">Change your mind?</div>
	<div style="padding-top: 10px; padding-bottom: 20px; text-align: center">
		<button mat-button style="border: 2px solid; border-radius: 10px; background: transparent; font-size: 15px; min-width: 100px; text-align: center" (click)="onCancel($event)">
			{{ fileCancelBtnLabel }}
		</button>
	</div>
</div> -->
