<ngx-spinner *ngIf="showSpinner" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
	<p style="color: white">Loading...</p>
</ngx-spinner>
<div style="height: 100%; overflow: hidden; padding-left: 50px; padding-right: 50px">
	<div class="header" style="height: inherit; display: flex; flex-direction: column; justify-content: space-between">
		<div class="row col-lg-12 col-md-12 custom-row" style="padding-top: 50px">
			<div style="display: flex; text-align: center; flex-direction: column">
				<img style="margin-left: auto; margin-right: auto" src="../../../assets/images/Trb_Bank_Logo_with_caption.png" height="100vh" width="600vh" />
			</div>
			<div style="display: flex; flex-direction: column; align-items: center; overflow-y: auto; padding: 40px; margin-top: 30px">
				<div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
					<h1 style="font-size: 35px; line-height: 50px; color: #8b7054">
						We are unable to validate your identification. Please go to your nearest Texas Regional Bank branch to create your account.
					</h1>
					<div *ngIf="selectedDebitCardApplication.nearestBranchAddressInfo" style="display: flex; flex-direction: column; margin-top: 15px">
						<a [href]="staticSiteLink" style="font-size: 25px; text-decoration: underline; align-self: center; margin-left: -10px; color: #063b6c">{{
							selectedDebitCardApplication.nearestBranchAddressInfo.branchInfo.branchLabel
						}}</a>
						<h3 style="font-size: 23px !important; text-decoration: none !important; line-height: 50px !important; margin-top: 15px; margin-left: 10px">
							{{ selectedDebitCardApplication.nearestBranchAddressInfo.addressInfo.address }}
						</h3>
						<h3 style="font-size: 23px !important; text-decoration: none !important; line-height: 50px !important; margin-top: -25px; margin-left: 10px">
							{{ selectedDebitCardApplication.nearestBranchAddressInfo.addressInfo.city }}, {{ selectedDebitCardApplication.nearestBranchAddressInfo.addressInfo.state }},
							{{ selectedDebitCardApplication.nearestBranchAddressInfo.addressInfo.zip }}
						</h3>
						<div style="display: flex; flex-direction: row; align-items: center; margin-top: -25px; margin-left: 10px">
							<h3 style="font-size: 23px !important; text-decoration: none !important">Phone:</h3>
							<h3 style="font-size: 23px !important; text-decoration: none !important; line-height: 50px !important; padding-left: 10px">
								{{ selectedDebitCardApplication.nearestBranchAddressInfo.branchInfo.primaryPhone }}
							</h3>
						</div>
					</div>
					<!-- <p style="font-size: 15px; color: #002b5c">
						One of our team members will contact you shortly. For account customizations, including the addition of account owners, please
						<a href="https://www.texasregionalbank.com/contact-us/" style="color: blue; text-decoration: underline" style="color: blue; text-decoration: underline">visit us</a> at one of
						our local branches.
					</p> -->
				</div>
				<a class="home-btn" href="https://www.texasregionalbank.com/">Return to the homepage</a>
			</div>
		</div>
	</div>
</div>
