import { NgModule, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationStart, Router, RouterModule, Routes } from '@angular/router';

import { HeaderService, HeaderType } from './services/header-service';
import { ApplicationListComponent } from './components/application-list/application-list.component';
import { ApplicationRouteGuard } from './guard/application-route-guard';
import { SendMessageComponent } from './components/send-message/send-message.component';

const routes: Routes = [
	{
		path: 'debitcardppplication',
		loadChildren: () => import('./modules/debit-card-application/debit-card-application.module').then((m) => m.DebitCardApplicationModule),
		data: {
			headerType: HeaderType.logoOnly,
			headerText: 'Debit Card Application',
		},
	},
	// This is a generic route which redirect user to appropriate component
	{
		path: 'debitcardppplication/:id',
		canActivate: [ApplicationRouteGuard],
		loadChildren: () => import('./modules/debit-card-application/debit-card-application.module').then((m) => m.DebitCardApplicationModule),
		data: {
			headerType: HeaderType.logoOnly,
			headerText: 'Debit Card Application',
		},
	},

	//{ path: 'clientapplicationstatus', component: ClientApplicationStatusComponent, data: { headerType: HeaderType.clientApplicationHeader}, canActivate: [UserValidationGuard] },
	{ path: 'applicationlist', component: ApplicationListComponent, data: { headerType: HeaderType.logoOnly } },
	{ path: 'sendmessage', component: SendMessageComponent, data: { headerType: HeaderType.logoOnly } },
	{ path: '**', redirectTo: 'applicationlist', data: { headerType: HeaderType.logoOnly } },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			onSameUrlNavigation: 'reload',
		}),
	],
	exports: [RouterModule],
	providers: [
		//VerficationService, AuthGuard, UserValidationGuard
	],
})
export class AppRoutingModule implements OnInit {
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		public headerService: HeaderService
	) {
		// this.router.routeReuseStrategy.shouldReuseRoute = () => {
		//   return false;
		// };
		this.router.events.subscribe((event) => {
			if (event instanceof ActivationStart) {
				const data = event.snapshot.data;
				if (data?.headerType) {
					this.headerService.headerType = data.headerType;
				}
				this.headerService.headerText = data?.headerText;
			}
		});
		// this.activatedRoute.data.subscribe(data => {
		//   console.log(data);
		//       if(data?.headerType){
		//         this.headerService.headerType = data.headerType;
		//       }
		//       this.headerService.headerText = data?.headerText;
		//   })
	}

	ngOnInit() {}
}
