import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, mergeMap, of } from 'rxjs';
import { TokenProviderService } from '../services/token-provider.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(private tokenService: TokenProviderService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this.tokenService.acquireToken().pipe(
			mergeMap((response) => {
				console.log('Acquired token -----', response.data);
				const modifiedRequest = request.clone({
					headers: request.headers.set('Authorization', `${response.data?.value}`),
				});
				return next.handle(modifiedRequest);
			})
		);
	}
}
