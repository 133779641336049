<ngx-spinner *ngIf="showSpinner" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
	<p style="color: white">Loading...</p>
</ngx-spinner>
<div [fluidHeight] class="row col-lg-12 col-md-12 application-container">
	<div class="header" style="height: inherit; display: flex; flex-direction: column; justify-content: space-between">
		<div class="row col-lg-12 col-md-12 custom-row" style="padding-top: 50px">
			<div class="header-image-container">
				<img class="header-image" src="../../../assets/images/Trb_Bank_Logo_with_caption.png" />
			</div>
			<div style="display: flex; flex-direction: column; align-items: center; overflow-y: auto; padding: 40px">
				<div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
					<h1 class="header-label">Great! We have received your application.</h1>
					<p class="header-para-label">
						One of our team members will contact you shortly. For account customizations, including the addition of account owners, please
						<a href="https://www.texasregionalbank.com/contact-us/" style="color: blue; text-decoration: underline" style="color: blue; text-decoration: underline">visit us</a> at one of
						our local branches.
					</p>
				</div>
				<div style="padding-top: 40px">
					<button mat-raised-button color="primary" style="font-size: 15px; margin-left: 15px; min-width: 100px">
						<a href="https://www.texasregionalbank.com/" style="color: white">Return to the homepage</a>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
