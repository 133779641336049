import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorHandlingService } from '../../../../../../../goldstar-share/src/app/services/error-handling.service';
import { DxValidationGroupComponent } from 'devextreme-angular';
import { CountryInfo, DebitCardApplicationInfo, DebitCardApplicationScreenState, DebitCardApplicationStatus, SpinnerState } from '../../models/model';
import { DebitCardApplicationDataStore } from '../../services/debit-card-application-data.store';
import { DebitCardApplicationItem } from '../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models/debit-card-application-item';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseApplicationComponent } from '../base-application/base-application.component';
import { DebitCardApplicationService } from '../../services/debit-card-application.service';
import { MasterDataService } from '../../services/master-data.service';
import { DebitCardApplication } from '../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { ResultHelper } from '../../common/result-extension';
import { Result } from '../../../../../../../goldstar-share/src/app/models/models';

@Component({
	selector: 'app-debit-card-login',
	templateUrl: './debit-card-login.component.html',
	styleUrls: ['./debit-card-login.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DebitCardLoginComponent extends BaseApplicationComponent implements OnInit {
	// This is for full footer (all 3 columns)
	fullFooterLayout: boolean = true;

	// This is one column (only continue button)
	trimmedFooter: boolean = false;

	application1Form!: UntypedFormGroup;
	screenState: DebitCardApplicationScreenState = {
		activeScreen: DebitCardApplicationStatus.IN_PROCESS_BEGIN_VERIFICATION,
	};

	searchModeOption = 'contains';

	searchExprOption: any = 'label';

	searchTimeoutOption = 200;

	minSearchLengthOption = 0;

	showDataBeforeSearchOption = false;

	searchExprOptionItems: Array<any> = [
		{
			name: "'code'",
			value: 'code',
		},
		{
			name: "['code', 'codeID']",
			value: ['code', 'codeID'],
		},
	];

	allStates: CountryInfo[] = [];

	isUsaCountryCodeSelected: boolean = true;
	isCanadaCountryCodeSelected: boolean = false;
	isMexicoCountryCodeSelected: boolean = false;
	isIndiaCountryCodeSelected: boolean = false;

	@HostListener('window:load', ['$event'])
	onLoad() {
		if (window.innerWidth <= 500) {
			this.fullFooterLayout = false;
			this.trimmedFooter = true;
		} else {
			this.fullFooterLayout = true;
			this.trimmedFooter = false;
		}
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize(event: any) {
		if (window.innerWidth <= 500) {
			this.fullFooterLayout = false;
			this.trimmedFooter = true;
		} else {
			this.fullFooterLayout = true;
			this.trimmedFooter = false;
		}
	}

	@ViewChild('application1FormValidationGroup', { static: false }) application1ValidationGroup!: DxValidationGroupComponent;

	constructor(
		formBuilder: UntypedFormBuilder,
		errorHandlingService: ErrorHandlingService,
		spinnerService: NgxSpinnerService,
		store: DebitCardApplicationDataStore,
		router: Router,
		activatedRoute: ActivatedRoute,
		debitCardApplicationService: DebitCardApplicationService,
		masterDataService: MasterDataService
	) {
		super(formBuilder, errorHandlingService, spinnerService, store, router, activatedRoute, debitCardApplicationService, masterDataService);
	}

	async ngOnInit(): Promise<void> {
		this.initializeApplicationForm();
		this.allStates = await this.masterDataService.fetchAllCountryInfo();
		if (this.selectedDebitCardApplication) {
			await this.bindApplication();
		}
	}

	async bindApplication() {
		this.setFullName();
		this.application1Form.controls.firstName.setValue(this.selectedDebitCardApplication.firstName);
		this.application1Form.controls.middleName.setValue(this.selectedDebitCardApplication.middleName);
		this.application1Form.controls.lastName.setValue(this.selectedDebitCardApplication.lastName);
		this.application1Form.controls.countryCode.setValue(await this.resolveCountryFullName(this.selectedDebitCardApplication.countryCode));
		this.application1Form.controls.cellPhoneNumber.setValue(this.selectedDebitCardApplication.primaryPhone);
		this.application1Form.controls.emailAddress.setValue(this.selectedDebitCardApplication.primaryEmail);
	}

	initializeApplicationForm() {
		this.application1Form = this.formBuilder.group({
			fullName: [null, Validators.required],
			firstName: [null, Validators.required],
			middleName: [null],
			lastName: [null, Validators.required],
			countryCode: [null, Validators.required],
			cellPhoneNumber: [null, Validators.required],
			emailAddress: [null, Validators.required],
			applicationStatus: [null],
		});
	}

	async navigateToPhoneVerification() {
		const validationResult = this.application1ValidationGroup.instance.validate();
		if (validationResult.isValid) {
			const validationResult = this.validateAndParseFullName(this.application1Form.controls.fullName.value);
			if (!validationResult.isSuccess) {
				this.errorHandlingService.showErrorMessage(validationResult.message ?? 'Failed to parse full name');
				return;
			}
			this.toggleSpinner(SpinnerState.Show);
			const updateDebitCardApplicationRequest: DebitCardApplicationInfo = {
				countryCode: await this.resolveCountryCode(this.application1Form.controls.countryCode.value),
				firstName: this.application1Form.controls.firstName.value,
				middleName: this.application1Form.controls.middleName.value,
				lastName: this.application1Form.controls.lastName.value,
				primaryPhone: this.application1Form.controls.cellPhoneNumber.value,
				primaryEmail: this.application1Form.controls.emailAddress.value,
				identityVerifiedYN: false,
			};

			await this.store
				.replaceValue(updateDebitCardApplicationRequest)
				.then(async (response) => {
					this.toggleSpinner(SpinnerState.Hide);
					this.errorHandlingService.showSuccessMessage('Successfully saved application');
					this.routeSelector(`debitcardppplication/phoneVerification`);
				})
				.catch((error) => {
					this.toggleSpinner(SpinnerState.Hide);
					this.errorHandlingService.showErrorMessage('Failed to save application');
				});
		}
	}

	validateAndParseFullName(fullName: string): Result<string> {
		try {
			const trimmedName = fullName.trim();
			const nameParts = trimmedName.split(' ');
			if (nameParts.length <= 1) return ResultHelper.failedResponse('First and last name are required');

			const firstName = nameParts[0];
			this.application1Form.controls.firstName.setValue(firstName);

			const lastName = nameParts[nameParts.length - 1];
			this.application1Form.controls.lastName.setValue(lastName === firstName ? null : lastName);

			const middleParts = nameParts.slice(1, nameParts.length - 1);
			let middleName = middleParts.join(' ');
			this.application1Form.controls.middleName.setValue(middleName);
			return ResultHelper.successResponse('Successfully validated and parsed name');
		} catch (error) {
			return ResultHelper.failedResponse('Failed to parse name');
		}
	}

	setFullName() {
		if (this.selectedDebitCardApplication) {
			let fullName = null;
			if (this.selectedDebitCardApplication.firstName && this.selectedDebitCardApplication.firstName != ' ') {
				fullName = this.selectedDebitCardApplication.firstName;
			}
			if (this.selectedDebitCardApplication.middleName && this.selectedDebitCardApplication.middleName != ' ' && this.selectedDebitCardApplication.middleName != '') {
				fullName = `${fullName} ${this.selectedDebitCardApplication.middleName}`;
			}
			if (this.selectedDebitCardApplication.lastName && this.selectedDebitCardApplication.lastName != ' ' && this.selectedDebitCardApplication.lastName != '') {
				fullName = `${fullName} ${this.selectedDebitCardApplication.lastName}`;
			}
			this.application1Form.controls.fullName.setValue(fullName);
		}
	}

	onValueChanged(e: any) {
		const newValue = e.value;
		switch (newValue) {
			case 'USA':
				this.isUsaCountryCodeSelected = true;
				this.isCanadaCountryCodeSelected = false;
				this.isMexicoCountryCodeSelected = false;
				this.isIndiaCountryCodeSelected = false;
				break;
			case 'MX':
				this.isUsaCountryCodeSelected = false;
				this.isCanadaCountryCodeSelected = false;
				this.isMexicoCountryCodeSelected = true;
				this.isIndiaCountryCodeSelected = false;
				break;
			case 'CA':
				this.isUsaCountryCodeSelected = false;
				this.isCanadaCountryCodeSelected = true;
				this.isMexicoCountryCodeSelected = false;
				this.isIndiaCountryCodeSelected = false;
				break;
			case 'IN':
				this.isUsaCountryCodeSelected = false;
				this.isCanadaCountryCodeSelected = false;
				this.isMexicoCountryCodeSelected = false;
				this.isIndiaCountryCodeSelected = true;
				break;
		}
	}

	async resolveCountryCode(countryFullName: string): Promise<string | undefined> {
		const matchingCountryInfo = await this.masterDataService.resolveCountryInfoFromCountryFullName(countryFullName);
		if (matchingCountryInfo.isSuccess) {
			return matchingCountryInfo.data?.countryCode ?? '';
		}
		return undefined;
	}

	async resolveCountryFullName(countryCode: string | null | undefined): Promise<string | undefined> {
		if (countryCode) {
			const matchingCountryInfo = await this.masterDataService.resolveCountryInfoFromCountryCode(countryCode);
			if (matchingCountryInfo.isSuccess) {
				return matchingCountryInfo.data?.countryFullName ?? '';
			}
		}
		return undefined;
	}
}
