import { Injectable } from '@angular/core';
import { LocationService } from '../../../../../../goldstar-share/src/app/services/location.service';
import { MasterDataService } from './master-data.service';
import { AddressInfo } from '../../../../../../goldstar-share/src/app/components/shared/address-autocomplete/address-autocomplete.component';
import { Branch } from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { BranchDistanceCollection, UserNearestBranchInfo } from '../models/model';
import { CollectionHelper } from '../../../../../../goldstar-share/src/app/services/collection.helper';
@Injectable({
	providedIn: 'root',
})
export class BranchLocatorService {
	constructor(private locationService: LocationService, private masterDataService: MasterDataService) {}

	async getNearestBranch(sourceAddress: AddressInfo): Promise<UserNearestBranchInfo> {
		const allStates = await this.masterDataService.fetchAllState();
		const branches = CollectionHelper.selectMany(allStates, (x) => x.branches ?? []);
		const distanceFromSourceToAllBranches: BranchDistanceCollection = {};
		branches.map((branch: Branch) => {
			const userNearestBranchInfo: UserNearestBranchInfo = {
				branchInfo: branch,
				addressInfo: {
					address: branch.branchAddress ?? '',
					city: branch.branchCity ?? '',
					latitude: branch.latitude ?? '',
					longitude: branch.longitude ?? '',
					state: branch.stateCode ?? '',
					zip: branch.zipCode ?? '',
					branchWebsiteUrl: branch.webSiteUrl ?? '',
				},
			};
			const distance = this.locationService.distanceInKiloMeter(sourceAddress, userNearestBranchInfo.addressInfo);
			distanceFromSourceToAllBranches[distance] = userNearestBranchInfo;
		});

		const nearestBranch = Object.keys(distanceFromSourceToAllBranches)
			.map((x) => Number.parseFloat(x))
			.filter((y) => !isNaN(y))
			.sort((val1, val2) => val1 - val2)[0];

		const nearestBranchAddressInfo = distanceFromSourceToAllBranches[nearestBranch];
		return nearestBranchAddressInfo;
	}
}
