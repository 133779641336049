<ngx-spinner *ngIf="showSpinner" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="true">
	<p style="color: white">Loading...</p>
</ngx-spinner>
<div [fluidHeight] class="row col-lg-12 col-md-12 application-container">
	<div class="header" style="height: inherit; display: flex; flex-direction: column; justify-content: space-between">
		<div class="row col-lg-12 col-md-12 custom-row">
			<div class="header-image-container">
				<img class="header-image" src="../../../assets/images/Trb_Bank_Logo_with_caption.png" />
			</div>
		</div>
		<div style="display: flex; flex-direction: column; align-items: center">
			<div>
				<p class="header-title">
					Enter the four digit code sent to
					<span class="header-title" *ngIf="selectedDebitCardApplication?.countryCode === '+1'">###-###-</span>
					<span class="header-title" *ngIf="selectedDebitCardApplication?.countryCode === '+52'">##-####-</span>
					{{ last4DigitOfPhoneNumber }}
				</p>
			</div>
			<div class="otp-form-container">
				<form [formGroup]="otpValidationForm">
					<dx-validation-group #otpValidationFormValidationGroup>
						<div class="col-lg-4 col-md-2 d-sm-none d-md-block"></div>
						<div class="otp-container">
							<ngx-otp-input #ngxOtp [config]="otpInputConfig" (fill)="onOtpValueChange($event)"></ngx-otp-input>
						</div>
						<span style="margin-top: 5px" [ngClass]="showOtpValidation == true ? 'error-message-visible' : 'error-message-hidden'">
							Verification code is invalid, please provide a valid verification code and try again!
						</span>
					</dx-validation-group>
				</form>
			</div>
			<div style="padding-top: 20px">
				<p class="footer-message">
					Message and data rates may apply. Didn't receive a code? <span class="hover-pointer" (click)="sendCodeOverSMS()">Click to resend</span>&nbsp;or&nbsp;<span
						class="hover-pointer"
						(click)="sendCodeOverCall()"
						>receive a phone call</span
					>&nbsp;with a code
				</p>
				<!-- <ul style="display: flex; flex-direction: column; align-items: center; font-size: 20px; color: grey">
					<li style="line-height: 30px; margin-left: -20px">Message and data rates may apply.</li>
					<li style="line-height: 30px; margin-left: -11px">Didn't receive a code? <span class="hover-pointer" (click)="sendCodeOverSMS()">Click to resend</span>&nbsp;or</li>
					<li style="line-height: 30px; margin-left: -11px"><span class="hover-pointer" (click)="sendCodeOverCall()">receive a phone call</span>&nbsp;with a code</li>
				</ul> -->
			</div>
		</div>
		<div class="row footer" style="padding-top: 10px; padding-right: 10px">
			<div *ngIf="fullFooterLayout" style="display: flex; flex-direction: row; justify-content: space-between">
				<div style="display: flex; flex-direction: row; align-items: center">
					<img src="../../../assets/images/lock_not_filled.svg" height="10vh" />
					<span style="font-size: 10px; color: lightgray"> Your security matters to us</span>
				</div>
				<img src="../../../assets/images/logo_watermark.png" height="30vh" />
				<button mat-button style="border: 2px solid; border-radius: 10px; background: transparent; font-size: 15px; min-width: 100px" (click)="navigateToApplication2()">Continue</button>
			</div>
			<div *ngIf="trimmedFooter" style="display: flex; flex-direction: row; justify-content: center">
				<button mat-button style="border: 2px solid; border-radius: 10px; background: transparent; font-size: 15px; min-width: 100px" (click)="navigateToApplication2()">Continue</button>
			</div>
		</div>
	</div>
</div>
