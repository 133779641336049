<app-client-header-logged-out *ngIf="headerService.headerType === 'clientHeaderLoggedOut'"> </app-client-header-logged-out>
<app-client-header-logo-only *ngIf="headerService.headerType === 'logoOnly'"></app-client-header-logo-only>
<!-- <app-client-header *ngIf="headerService.headerType === 'clientHeader'"></app-client-header> -->
<app-loan-application-header [headerText]="headerService.headerText" *ngIf="headerService.headerType === 'clientApplicationHeader'"></app-loan-application-header>

<!-- <select style="margin-top: 65px;float: right; margin-right: 5px; margin-bottom: 5px;" #langSelect (change)="translate.use(langSelect.value)">
		<option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
	</select> -->

<!-- <p-sidebar [(visible)]="helpMenuService.visibility" position="right">
	<div [innerHTML]="helpMenuService.content"></div>
</p-sidebar> -->
<br />
<br />
<router-outlet></router-outlet>
