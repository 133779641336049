import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogBtnAction, ICustomDialogComponentData } from '../custom-dialog/custom-dialog.component';

@Component({
	selector: 'app-custom-client-dialog',
	templateUrl: './custom-client-dialog.component.html',
	styleUrls: ['./custom-client-dialog.component.scss'],
})
export class CustomClientDialogComponent implements OnInit {
	constructor(public dialogRef: MatDialogRef<CustomClientDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ICustomDialogComponentData) {}

	ngOnInit() {}

	onYesClick(): void {
		this.data.btnAction = DialogBtnAction.Confirm;
		this.dialogRef.close(this.data);
	}

	onNoClick(): void {
		this.data.btnAction = DialogBtnAction.Cancel;
		this.dialogRef.close(this.data);
	}
}
