import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { Subscription, debounceTime, fromEvent, throttleTime } from 'rxjs';

@Directive({
	selector: '[appControlHeight]',
})
export class ControlHeightDirective {
	private readonly heightOffset: number = 85;
	@Input('appControlHeight') topOffset!: string | undefined;

	private domElement: HTMLElement;
	private resizeSub: Subscription;

	constructor(private renderer: Renderer2, private elementRef: ElementRef) {
		// get ref HTML element
		this.domElement = this.elementRef.nativeElement as HTMLElement;

		// register on window resize event
		this.resizeSub = fromEvent(window, 'resize')
			.pipe(throttleTime(500), debounceTime(500))
			.subscribe(() => this.setHeight());
	}

	ngAfterViewInit() {
		this.setHeight();
	}

	ngOnDestroy() {
		this.resizeSub.unsubscribe();
	}

	private setHeight() {
		const windowHeight = window?.innerHeight;
		let itemHeight: number = 50;

		if (windowHeight <= 768) {
			itemHeight = 35;
		}

		if (windowHeight <= 400) {
			itemHeight = 30;
		}

		this.renderer.setStyle(this.domElement, 'height', `${itemHeight}px`);
	}
}
