<div class="dialog-container">
	<div mat-dialog-content class="header-container">
		<p class="header">{{ data.body }}</p>
	</div>
	<div mat-dialog-actions>
		<div class="col-lg-12 col-md-12 col-sm-12 text-right">
			<button class="confirmation-button" mat-raised-button color="primary" type="button" (click)="onYesClick()">
				{{ data.confirmBtnLabel }}
			</button>
			<button class="cancel-button" mat-raised-button color="primary" type="button" (click)="onNoClick()">
				{{ data.cancelBtnLabel }}
			</button>
		</div>
	</div>
</div>
